import { Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import React from 'react'
import { IBButton, IBGeneralField, IBGeneralTable, TypeInputComponent } from '@pulseops/inbound'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { AppContext, assets, ErrorHandling, ModalComponent, SelectOption } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { A2S1ConfigList, A2S3ConfigList, AdmintoolService, OBCallingResultDetailService, OBTaskService } from '@pulseops/outbound'
import _ from 'lodash'
import { getStyleStatus } from './commonFunction'
import { useLoading } from '@mxt/zio-react'
import { useIsFocused } from '@react-navigation/native'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import moment from 'moment'
type SearchForm = {
  callProgram: SelectOption | null
  status: SelectOption | null
}

type QuestionForm = {
  callProgram: string,
  questionVN: string,
  questionEN: string,
  status: SelectOption | null
  questionCode?: string
}

type AnswersOfQuestionForm = {
  questionCode: string,
  answerCode: SelectOption | null,
}

const EditPopup = ({ isOpenEditPopup, setOpenEditPopup, data, id, setReloadFlag }: {
  isOpenEditPopup: boolean,
  setOpenEditPopup: (val: boolean) => void,
  data: A2S1ConfigList["data"],
  id: string,
  setReloadFlag: (val: boolean) => void
}) => {
  const { t, i18n } = useTranslation()
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const listStatus = [{ label: t('Outbound:AdminTool:ActiveA2S1'), value: "active" }, { label: t('Outbound:AdminTool:InactiveA2S1'), value: "inactive" }]
  const dataEdit = data.find(x => x.id === id)
  const defaultValues = {
    callProgram: dataEdit?.transactionTypeWF || "",
    questionEN: dataEdit?.questionDescEn || "",
    questionVN: dataEdit?.questionDescVN || "",
    status: listStatus.find(x => dataEdit?.status ? x.value === "active" : x.value === "inactive"),
    questionCode: dataEdit?.questionCode || ""
  }

  const questionForm = useForm<QuestionForm>({
    defaultValues: defaultValues
  })

  const onEdit = questionForm.handleSubmit((value) => {
    pipe(AdmintoolService.updateQuestionA2S1({
      id: dataEdit?.id || "",
      category: "OB",
      transactionTypeWF: value.callProgram,
      questionCode: dataEdit?.questionCode || "",
      questionDescEn: value.questionEN,
      questionDescVN: value.questionVN,
      questionOrder: 0,
      status: value.status?.value === "active" ? true : false
    }),
      ZIO.map((res) => {
        setReloadFlag(true)
        showToast(t('message:OB0040'), "success")
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  })

  return (
    <ModalComponent
      visible={isOpenEditPopup}
      onClose={() => setOpenEditPopup(false)}
      title={t('Outbound:AdminTool:Edit')}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          action: () => setOpenEditPopup(false),
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 35, marginRight: 10 }
        },
        {
          text: t('common:Save'),
          type: 'filled',
          action: () => onEdit(),
          disabled: _.isEqual(defaultValues, questionForm.watch()),
          loading: false,
          onlyWide: false,
          style: { height: 35 }
        }
      ]}
      centerTitle
      modalWidth={800}
    >
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralField
          FieldForm={questionForm}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionCode',
              title: t('Outbound:AdminTool:QuestionCode'),
              inputType: 'input',
              disabled: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'callProgram',
              title: t('Outbound:AdminTool:CallProgram'),
              inputType: 'input',
              disabled: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionVN',
              title: t('Outbound:AdminTool:Question_VN'),
              required: true,
              inputType: 'text',
              expandRow: 2,
              multiline: true,
              numberOfLines: 3,
              maxLength: 500,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Question_VN') }) }
              },
              disabled: dataEdit?.transactionTypeWF === "INDEPENDENT_CHECK"
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionEN',
              title: t('Outbound:AdminTool:Question_EN'),
              inputType: 'text',
              required: true,
              expandRow: 2,
              multiline: true,
              numberOfLines: 3,
              maxLength: 500,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Question_EN') }) }
              },
              disabled: dataEdit?.transactionTypeWF === "INDEPENDENT_CHECK"
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'status',
              title: t('Outbound:AdminTool:Status'),
              option: listStatus,
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Status') }) }
              }
            },
          ]}
        />
      </View>
    </ModalComponent>
  )
}

const AddPopup = ({ isOpenAddPopup, setOpenAddPopup, data, setReloadFlag }: {
  isOpenAddPopup: boolean,
  setOpenAddPopup: (val: boolean) => void,
  data: A2S1ConfigList["data"],
  setReloadFlag: (val: boolean) => void
}) => {
  const { t, i18n } = useTranslation()
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const questionForm = useForm<QuestionForm>({
    defaultValues: {
      callProgram: data[0].transactionTypeWF || "",
      questionEN: "",
      questionVN: "",
      status: { label: t('Outbound:AdminTool:ActiveA2S1'), value: "active" }
    }
  })
  const onAdd = questionForm.handleSubmit((value) => {
    pipe(AdmintoolService.createNewQuestionA2S1({
      category: "OB",
      transactionTypeWF: value.callProgram,
      questionDescEn: value.questionEN,
      questionDescVN: value.questionVN,
      questionOrder: 0,
      status: value.status?.value === "active" ? true : false
    }),
      ZIO.map((res) => {
        setReloadFlag(true)
        showToast(t('message:OB0113'), "success")
        setOpenAddPopup(false)
        return ZIO.unit
      }),
      ZIO.unsafeRun({})
    )
  })

  return (
    <ModalComponent
      visible={isOpenAddPopup}
      onClose={() => setOpenAddPopup(false)}
      title={t('Outbound:AdminTool:CreateNew')}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          action: () => setOpenAddPopup(false),
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 35, marginRight: 10 }
        },
        {
          text: t('common:Add'),
          type: 'filled',
          action: () => onAdd(),
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 35 }
        }
      ]}
      centerTitle
      modalWidth={800}
    >
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralField
          FieldForm={questionForm}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'callProgram',
              title: t('Outbound:AdminTool:CallProgram'),
              inputType: 'input',
              disabled: true
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionVN',
              title: t('Outbound:AdminTool:Question_VN'),
              required: true,
              inputType: 'text',
              expandRow: 2,
              multiline: true,
              numberOfLines: 3,
              maxLength: 500,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Question_VN') }) }
              }
            },
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionEN',
              title: t('Outbound:AdminTool:Question_EN'),
              inputType: 'text',
              required: true,
              expandRow: 2,
              multiline: true,
              numberOfLines: 3,
              maxLength: 500,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Question_EN') }) }
              }
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'status',
              title: t('Outbound:AdminTool:Status'),
              option: [{ label: t('Outbound:AdminTool:ActiveA2S1'), value: "active" }, { label: t('Outbound:AdminTool:InactiveA2S1'), value: "inactive" }],
              required: true,
              rules: {
                required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Status') }) }
              }
            },
          ]}
        />
      </View>
    </ModalComponent>
  )
}

const AnswersOfQuestionPopup = ({ isOpenQAPopup, setOpenQAPopup, data, questionCode }: {
  isOpenQAPopup: boolean,
  setOpenQAPopup: (val: boolean) => void,
  data: A2S1ConfigList["data"],
  questionCode: string
}) => {
  const { t, i18n } = useTranslation()
  const [isAnswerList, setAnswerList] = React.useState<{
    order: number,
    listCode: string,
    answerVN: string
  }[]>([])
  const [a2s3, setA2S3] = React.useState<A2S3ConfigList["data"]>([])
  const [isChange, setChange] = React.useState<boolean>(false)
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoading] = useLoading(false)
  const { height, width } = useWindowDimensions()

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const awnswerOfQuestionForm = useForm<AnswersOfQuestionForm>({
    defaultValues: {
      questionCode: questionCode,
      answerCode: null
    }
  })

  React.useEffect(() => {
    pipe(
      ZIO.zipPar(AdmintoolService.getListA2S2Config(), AdmintoolService.getListA2S3Config()),
      ZIO.map(([a2s2, a2s3]) => {
        setA2S3(a2s3)
        setAnswerList(_.orderBy(a2s2.filter(c => c.questionCode === questionCode), "answerOrder", 'asc').map((x, i) => ({
          order: i + 1,
          listCode: x.answerCode || "",
          answerVN: a2s3.find(z => z.answerCode === x.answerCode)?.answerVN || ""
        })))
        return ZIO.unit
      }),
      ZIO.unsafeRun({}))
  }, [])

  React.useEffect(() => {
    awnswerOfQuestionForm.setValue("answerCode", null)
  }, [isAnswerList])

  const columns = [
    {
      label: t('Outbound:AdminTool:Order'),
      field: 'order',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:AnswerCode'),
      field: 'listCode',
      disabled: true,
    },
    {
      label: t('Outbound:AdminTool:Answer_VN'),
      field: 'answerVN',
      disabled: true,
      render: (val: string) => {
        return <View style={{ width: "100%" }}><Text>{val}</Text></View>
      }
    },
    {
      label: "",
      field: "listCode",
      render: (val: string) => {
        return <Pressable onPress={() => {
          setChange(true)
          setAnswerList(isAnswerList.filter(x => x.listCode !== val).map((x, i) => ({ ...x, order: i + 1 })))
        }}>
          <assets.DeleteBin />
        </Pressable>
      }
    },
    {
      label: '',
      field: 'order',
      disabled: true,
      draggable: true,
      render: (value: string) => {
        return (
          <Pressable>
            <assets.OBReorderTable />
          </Pressable>
        )
      }
    }
  ]

  const onAdd = awnswerOfQuestionForm.handleSubmit((value) => {
    pipe(AdmintoolService.updateAnswersOfQuestionA2S2(
      {
        category: "OB",
        transactionTypeWF: data[0].transactionTypeWF || "",
        questionCode: questionCode,
        a2S2AnswerOrderDTOs: isAnswerList.map(x => ({ answerCode: x.listCode || "", answerOrder: x.order }))
      }), ZIO.map((res) => {
        setOpenQAPopup(false)
        showToast(t('message:OB0040'), "success")
        return ZIO.unit
      }),
      ZIO.unsafeRun({}))
  })


  return (
    <ModalComponent
      visible={isOpenQAPopup}
      onClose={() => setOpenQAPopup(false)}
      title={t('Outbound:AdminTool:ListAnswerOfQuestion')}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          action: () => setOpenQAPopup(false),
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 35, marginRight: 10 }
        },
        {
          text: t('common:Save'),
          type: 'filled',
          action: () => onAdd(),
          disabled: false,
          loading: false,
          onlyWide: false,
          style: { height: 35 },
          hideButton: !isChange
        }
      ]}
      centerTitle
      modalWidth={width * 0.7}
    >
      <ScrollView style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralField
          FieldForm={awnswerOfQuestionForm}
          col={2}
          typeInput={[
            {
              type: TypeInputComponent.INPUT,
              formName: 'questionCode',
              title: t('Outbound:AdminTool:QuestionCode'),
              inputType: 'input',
              disabled: true
            },
            {
              type: TypeInputComponent.SELECT,
              formName: 'answerCode',
              title: t('Outbound:AdminTool:AnswerCode'),
              option: _.orderBy(a2s3, "answerCode", "asc").filter(x => !isAnswerList.map(z => z.listCode).includes(x.answerCode || ""))
                .map((x) => ({ label: `${x.answerCode} - ${x.answerVN}`, value: x.answerCode || "" })),
              handleData: (value: SelectOption | null) => {
                if (value) {
                  setChange(true)
                  setAnswerList([...isAnswerList, {
                    order: isAnswerList.length + 1,
                    listCode: value.value,
                    answerVN: a2s3.find(x => x.answerCode === value.value)?.answerVN || ""
                  }])
                }
              }
            },
          ]}
        />
        <View>
          <IBGeneralTable
            dataTable={columns}
            data={isAnswerList}
            setDataDraggable={(data: any[]) => {
              setAnswerList(data)
              setChange(true)
            }}
            maxHeight={height * 0.4}
          />
        </View>
      </ScrollView>
    </ModalComponent>
  )
}

const ListAnswersOfQuestionPopup = ({ isOpenListQAPopup, setOpenListQAPopup, data }: {
  isOpenListQAPopup: boolean,
  setOpenListQAPopup: (val: boolean) => void,
  data: A2S1ConfigList["data"]
}) => {
  const { t, i18n } = useTranslation()
  const [dataList, setDataList] = React.useState<{ questionCode: string, answerCode: string, questionVN: string, answerVN: string }[]>([])
  const { showGlobalLoading, showToast } = React.useContext(AppContext.AppContextInstance)
  const [isLoading, bindLoading] = useLoading(false)
  const { height, width } = useWindowDimensions()

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])


  React.useEffect(() => {
    pipe(
      ZIO.zipPar(AdmintoolService.getListA2S2Config(), AdmintoolService.getListA2S3Config()),
      ZIO.map(([a2s2, a2s3]) => {
        const remapData = data.map(x => ({ questionCode: x.questionCode, questionOrder: x.questionOrder }))
        const filterA2S2 = a2s2.map(x => ({ ...x, questionOrder: remapData.find(z => z.questionCode === x.questionCode)?.questionOrder })).filter(x => remapData.map(x => x.questionCode).includes(x.questionCode))
        setDataList(
          _.orderBy(filterA2S2, "questionOrder", "asc").map(x => ({
            questionCode: x.questionCode || "",
            answerCode: x.answerCode || "",
            questionVN: data.find(z => z.questionCode === x.questionCode)?.questionDescVN || "",
            answerVN: a2s3.find(c => c.answerCode === x.answerCode)?.answerVN || ""
          }))
        )
        return ZIO.unit
      }),
      ZIO.unsafeRun({}))
  }, [])


  const columns = [
    {
      label: t('Outbound:AdminTool:QuestionCode'),
      field: 'questionCode',
    },
    {
      label: t('Outbound:AdminTool:AnswerCode'),
      field: 'answerCode',
    },
    {
      label: t('Outbound:AdminTool:Question_VN'),
      field: 'questionVN',
      render: (val: string) => {
        return <View style={{ width: "100%" }}><Text>{val}</Text></View>
      }
    },
    {
      label: t('Outbound:AdminTool:Answer_VN'),
      field: 'answerVN',
      render: (val: string) => {
        return <View style={{ width: "100%" }}><Text>{val}</Text></View>
      }
    }
  ]


  return (
    <ModalComponent
      visible={isOpenListQAPopup}
      onClose={() => setOpenListQAPopup(false)}
      title={t('Outbound:AdminTool:AnswersOfQuestionList')}
      actions={[]}
      centerTitle
      modalWidth={width * 0.8}
    >
      <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
        <IBGeneralTable
          dataTable={columns}
          data={dataList}
          maxHeight={height * 0.7}
        />
      </View>
    </ModalComponent>
  )
}
interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }

export const ConfigA2S1A2S2QAListScreen = ({ navigation }: Props) => {
  const { t, i18n } = useTranslation()
  const { height, width } = useWindowDimensions()
  const [isOpenAddPopup, setOpenAddPopup] = React.useState<boolean>()
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>()
  const [isOpenQAPopup, setOpenQAPopup] = React.useState<boolean>()
  const [isOpenListQAPopup, setOpenListQAPopup] = React.useState<boolean>()
  const [isIdEdit, setIdEdit] = React.useState<string>()
  const [isQCodeEdit, setQCodeEdit] = React.useState<string>()
  const [data, setData] = React.useState<A2S1ConfigList["data"]>([])
  const [isReorder, setReorder] = React.useState<boolean>(false)
  const [isReloadFlag, setReloadFlag] = React.useState<boolean>(false)
  const [isLoading, bindLoading] = useLoading(false)
  const [isChangeOrder, setChangeOrder] = React.useState<boolean>(false)
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const isFocused = useIsFocused()

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:A2S1_A2S2'), navigate: null }
      ])
    }

  }, [isFocused])

  const { callProgramList } = pipe(
    AdmintoolService.getListA2S1Config(),
    ZIO.map((a2s1) => {
      return {
        callProgramList: a2s1.map(x => x.transactionTypeWF),
      }
    }),
    ErrorHandling.runDidMount()
  ) || {
    callProgramList: [],
  }


  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])


  const searchForm = useForm<SearchForm>({
    defaultValues: {
      callProgram: null,
      status: null
    }
  })

  const columns = [
    {
      label: t('Outbound:AdminTool:Order'),
      field: 'order',
      disabled: true
    },
    {
      label: t('Outbound:AdminTool:QuestionCode'),
      field: 'questionCode',
      disabled: true,
      render: (value: string) => {
        return data.some(x => x.status) ? <TouchableOpacity
          onPress={() => {
            setQCodeEdit(value)
            setOpenQAPopup(true)
          }}
        >
          <Text
            style={{
              fontWeight: '600',
              fontSize: 15,
              color: '#1ea5fc',
              textDecorationLine: 'underline'
            }}
          >
            {value}
          </Text>
        </TouchableOpacity> : <Text>{value}</Text>
      }
    },
    {
      label: t('Outbound:AdminTool:Question_VN'),
      field: 'questionDescVN',
      disabled: true,
      render: (val: string) => {
        return <View style={{ width: 350 }}><Text>{val}</Text></View>
      }
    },
    {
      label: t('Outbound:AdminTool:Question_EN'),
      field: 'questionDescEn',
      disabled: true,
      render: (val: string) => {
        return <View style={{ width: 350 }}><Text>{val}</Text></View>
      }
    },
    {
      label: t('Outbound:AdminTool:Status'),
      field: 'status',
      render: (value: string) => {
        return <Text style={getStyleStatus(value ? "Active" : "Inactive")}>{value ? t('Outbound:AdminTool:ActiveA2S1') : t('Outbound:AdminTool:InactiveA2S1')}</Text>
      },
      disabled: true

    },
    {
      label: t('Outbound:AdminTool:UpdatedDate'),
      field: 'updatedDate',
      disabled: true,
      format: "date"
    },
    {
      label: '',
      field: 'id',
      disabled: true,
      render: (value: string) => {
        return (
          <TouchableOpacity
            onPress={() => {
              setIdEdit(value)
              setOpenEditPopup(true)
            }}
          >
            <assets.OBPencilGray />
          </TouchableOpacity>
        )
      }
    },
    {
      label: '',
      field: 'order',
      disabled: true,
      visible: data.some(x => x.status),
      draggable: isReorder,
      render: (value: string) => {
        return (
          <Pressable>
            <assets.OBReorderTable />
          </Pressable>
        )
      }
    }
  ]

  React.useEffect(() => {
    if (isReloadFlag) {
      onSearch()
      setReloadFlag(false)
    }
  }, [isReloadFlag])

  const onSearch = searchForm.handleSubmit((value) => {
    const { callProgram, status } = value
    setReorder(false)
    pipe(AdmintoolService.getListA2S1Config(),
      ZIO.map((res) => {
        const statusFilter = status?.value === "active" ? true : false
        const filterData = res.filter(x => x.transactionTypeWF === callProgram?.value && statusFilter === x.status)
        setData(_.orderBy(filterData, "questionOrder", "asc").map((x, i) => ({ ...x, order: i + 1 })))
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({}))
  })

  const handleSaveTheOrder = () => {
    pipe(AdmintoolService.updateOrderQuestionA2S1(
      {
        category: "OB",
        transactionTypeWF: data[0].transactionTypeWF || "",
        a2S1QuestionOrderDTOs: data.map(x => ({ id: x.id || "", questionOrder: x.order || null }))
      }), ZIO.map((res) => {
        setChangeOrder(false)
        showToast(t('message:OB0040'), "success")
        setReorder(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({}))
  }

  return (
    <View style={styles.container}>
      <ScrollView style={{ padding: 1 }}>
        <View style={styles.searchContainer}>
          <IBGeneralField
            FieldForm={searchForm}
            col={3}
            typeInput={[
              {
                type: TypeInputComponent.SELECT,
                formName: 'callProgram',
                title: t('Outbound:AdminTool:CallProgram'),
                option: _.uniq(callProgramList).map((x) => ({ label: x || "", value: x || "" })),
                required: true,
                rules: {
                  required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:CallProgram') }) }
                }
              },
              {
                type: TypeInputComponent.SELECT,
                formName: 'status',
                title: t('Outbound:AdminTool:Status'),
                option: [{ label: t('Outbound:AdminTool:ActiveA2S1'), value: "active" }, { label: t('Outbound:AdminTool:InactiveA2S1'), value: "inactive" }],
                required: true,
                rules: {
                  required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Status') }) }
                }
              }
            ]}
          />
          <View style={styles.buttonContainer}>
            <IBButton
              onPress={onSearch}
              title={t('common:Search')}
              backgroundFill
              disabled={_.isNil(searchForm.watch().callProgram && searchForm.watch().status)}
            />
          </View>
        </View>
        <View style={{ marginLeft: 15 }}>
          {searchForm.watch().callProgram?.value === "INDEPENDENT_CHECK" ? <Text style={{ color: '#ED1B2C', fontSize: 15 }}>{t("message:OB0112")}</Text> : null}
        </View>
        <View style={styles.buttonBar}>
          <View>
            <IBButton
              onPress={() => {
                setOpenListQAPopup(true)
              }}
              title={t('Outbound:AdminTool:ListQuestionAndAnswer')}
              backgroundFill={false}
              disabled={_.isEmpty(data)}
            />
          </View>
          <View style={{ flexDirection: "row" }}>
            {isReorder ? <IBButton
              onPress={handleSaveTheOrder}
              title={t('Outbound:AdminTool:SaveTheOrder')}
              backgroundFill={false}
              containerStyle={{ marginRight: 15 }}
              disabled={!isChangeOrder || !data.some(x => x.status)}
            /> : <IBButton
              onPress={() => { setReorder(true) }}
              title={t('Outbound:AdminTool:Reorder')}
              backgroundFill={false}
              containerStyle={{ marginRight: 15 }}
              disabled={!data.some(x => x.status)}
              prefixIcon={data.some(x => x.status) ? <assets.OBReorderButton /> : <assets.OBReorderButtonDisabled />}
            />}
            <IBButton
              onPress={() => {
                setOpenAddPopup(true)
              }}
              title={`+ ${t('Outbound:AdminTool:Add')}`}
              backgroundFill
              disabled={_.isEmpty(data)}
            />
          </View>
        </View>
        <IBGeneralTable
          dataTable={columns}
          data={data.map(x => ({ ...x, updatedDate: moment(x.createdDate).format("YYYYMMDD") }))}
          // maxHeight={height - height * 0.25}
          // maxWidth={width - width * 0.06}
          // maxWidthContainer
          setDataDraggable={(data: any[]) => {
            setData(data)
            setChangeOrder(true)
          }}
        // filterColumn={filterColumnList}
        />
      </ScrollView>
      {isOpenAddPopup &&
        <AddPopup isOpenAddPopup={isOpenAddPopup} setOpenAddPopup={setOpenAddPopup} data={data} setReloadFlag={setReloadFlag} />
      }
      {isOpenEditPopup &&
        <EditPopup isOpenEditPopup={isOpenEditPopup} setOpenEditPopup={setOpenEditPopup} data={data} id={isIdEdit || ""} setReloadFlag={setReloadFlag} />
      }
      {isOpenQAPopup &&
        <AnswersOfQuestionPopup isOpenQAPopup={isOpenQAPopup} setOpenQAPopup={setOpenQAPopup} questionCode={isQCodeEdit || ""} data={data} />}
      {isOpenListQAPopup && <ListAnswersOfQuestionPopup data={data} isOpenListQAPopup={isOpenListQAPopup} setOpenListQAPopup={setOpenListQAPopup} />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8
  },
  searchContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#D3DCE6',
    margin: 15,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flex: 1,
    flexDirection: 'column'
  },
  buttonContainer: {
    alignItems: 'flex-start',
    width: '100%'
  },
  buttonBar: {
    flexDirection: "row",
    margin: 15,
    justifyContent: "space-between"
  }
})
