import { TaskDetail, TransactionType, SubTaskType, SourceType } from '@pulseops/common'
import { VeriPayloadData } from 'libs/common/src/service/model/task-detail/InquiryComplaint'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Text } from 'react-native'
import ChangeOccupationPersonal from './ChangeOccupationPersonal'
import { CommonRequest } from './CommonRequest'

import {
  Assignment,
  BeneficiaryDesignation,
  BillingChange,
  BillingChangeReversal,
  BonusSurrender,
  CancelFromInception,
  CancelRider,
  ChangeClientInfo,
  ChangeContactInfo,
  ChangeContactInfoAddress,
  ChangeContactInfoEmail,
  ChangeContactInfoPhone,
  ConfirmationPremiumReceipt,
  EServiceRegistration,
  ExcellentStudentAward,
  ExcessPremium,
  FATCADeclaration,
  FullSurrenderReversal,
  FundSwitching,
  GoAbroad,
  Inheritance,
  InquiryComplaint,
  LoanPayment,
  LoanStatements,
  Maturity,
  MaturityAdvance,
  PartialSurrender,
  FullSurrender,
  ChangeSumAssured,
  ChangePremium,
  PaidUp,
  PartialWithDrawal,
  PolicyCertificate,
  PremiumRedirection,
  PrucashPayment,
  Prukid369,
  Reinstatement,
  ScheduledPaymentStatement,
  SignatureRegistration,
  SpecialBonus,
  StopAPL,
  Topup,
  ProductOptionSwitching,
  VERedatingScreen,
  CashLess,
  DsSevicesDetail,
  RiderAlteration,
  RiderReinstatement,
  JournalScreen,
  MajorCombinedChange,
  DsAgentVerificationDetail,
  PartialWithDrawalDebit
} from './index'
import { InquiryComplaintForm } from './inquiry-complaint'
import ChangeDOBGenderScreen from './ChangeDOBGender'
import ChangeOtherInfomationPersonalScreen from './ChangeOtherInfomationPersonal'
import ChangeNationalIDCardScreen from './ChangeNationalIDCard'
interface Props {
  detail: TaskDetail.Type | null
  inquiryComplaintForm: UseFormReturn<InquiryComplaintForm>
  subTaskCode: string
  isHistory: boolean | null
  isInquiry: boolean | null
  isSuspend: boolean
  isQC: boolean | null
  onSubmitInquiry: (isSubmit: boolean) => void
  roles: string[]
  isNotCheckValidator: boolean
  onCheckValidator: (isValidator: boolean) => void
  basket: SubTaskType
  setUpdatedIACPayloadData: (data: VeriPayloadData) => void
  updatedIACPayloadData?: VeriPayloadData
  isChangeTab?: boolean
  setIsScroll?: (ischeck: boolean) => void
  isScroll?: boolean
  setIsHiddenButtonsForIACHistory?: (val: boolean) => void
}

export const RequestInfoTransactions: React.FC<Props> = ({
  detail,
  inquiryComplaintForm,
  isHistory,
  isInquiry,
  isSuspend,
  subTaskCode,
  isQC,
  roles,
  onSubmitInquiry,
  isNotCheckValidator,
  onCheckValidator,
  basket,
  setUpdatedIACPayloadData,
  updatedIACPayloadData,
  isChangeTab,
  setIsScroll,
  isScroll,
  setIsHiddenButtonsForIACHistory
}) => {
  const isSeaBankShowedMessage = (): boolean => {
    return !isSuspend && !isQC && !isHistory && !isInquiry
  }
  const renderDetail = () => {
    switch (detail?.payload?.tag) {
      case TransactionType.BENEFICIARY_DESIGNATION:
        return <BeneficiaryDesignation detail={detail?.payload} isSeaBankShowedMessage={isSeaBankShowedMessage()} />
      case TransactionType.BILLING_CHANGE:
      case TransactionType.BILLING_CHANGE_CORPORATE:
        return <BillingChange detail={detail?.payload} />
      case TransactionType.LOAN_STATEMENTS:
        return <LoanStatements detail={detail?.payload} />
      case TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_REQUEST:
      case TransactionType.AUTO_DEBIT_TOPUP_ACCOUNT_CANCEL:
        return <PartialWithDrawalDebit detail={detail?.payload} />
      case TransactionType.PARTIAL_WITHDRAWAL:
        return <PartialWithDrawal detail={detail?.payload} isSeaBankShowedMessage={isSeaBankShowedMessage()} />
      case TransactionType.CHANGE_CONTACT_INFO_EMAIL:
        return <ChangeContactInfoEmail detail={detail?.payload} />
      case TransactionType.CHANGE_CONTACT_INFO_PHONE:
        return <ChangeContactInfoPhone detail={detail?.payload} />
      // case TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL:
      //   return <ChangeContactInfoPhoneAndEmail detail={detail?.payload} />
      case TransactionType.CHANGE_CONTACT_INFO_ADDRESS:
      case TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL:
        return <ChangeContactInfoAddress detail={detail?.payload} />

      // case TransactionType.CHANGE_CONTACT_INFO_ADDRESS_DIGITAL:
      //   return <ChangeContactInfoAddress detail={detail?.payload} />

      case TransactionType.BILLING_FREQUENCY_REVERSAL:
        return <BillingChangeReversal detail={detail?.payload} />
      case TransactionType.SIGNATURE_REGISTRATION:
      case TransactionType.HEALTH_DECLARATION:
        // case TransactionType.ADD_LACK_OF_DOCUMENT:
        return <SignatureRegistration />
      case TransactionType.ASSIGNMENT:
        return <Assignment detail={detail?.payload} />
      case TransactionType.CONFIRMATION_OF_PREMIUM_RECEIPT:
        return <ConfirmationPremiumReceipt detail={detail?.payload} />
      case TransactionType.GO_ABROAD:
        return <GoAbroad detail={detail?.payload} />
      case TransactionType.MATURITY:
        return <Maturity detail={detail.payload} />
      case TransactionType.PARTIAL_SURRENDER:
        return <PartialSurrender detail={detail.payload} />
      case TransactionType.FULL_SURRENDER:
        return <FullSurrender detail={detail.payload} isSeaBankShowedMessage={isSeaBankShowedMessage()} />
      case TransactionType.CHANGE_SUM_ASSURED:
        return <ChangeSumAssured detail={detail.payload} />
      case TransactionType.CHANGE_PREMIUM:
        return <ChangePremium detail={detail.payload} />
      case TransactionType.CHANGE_CONTACT_INFO:
      case TransactionType.CHANGE_CONTACT_INFO_PHONE_AND_EMAIL:
        return <ChangeContactInfo detail={detail?.payload} />
      case TransactionType.REINSTATEMENT:
        return <Reinstatement detail={detail?.payload} />
      case TransactionType.CHANGE_NATIONAL_ID_CARD:
      case TransactionType.CHANGE_CLIENT_INFO: {
        const { source = '', tag = '' } = detail?.payload ?? {}
        if (
          tag === TransactionType.CHANGE_NATIONAL_ID_CARD &&
          [SourceType.ZALO, SourceType.PRUONLINE, SourceType.PULSE4OPS].includes(source as SourceType)
        ) {
          return <ChangeNationalIDCardScreen detail={detail?.payload as any} />
        }
        return <ChangeClientInfo detail={detail?.payload as any} />
      }

      case TransactionType.TOP_UP:
        return <Topup detail={detail.payload} />
      case TransactionType.PRUCASH_PAYMENT:
        return <PrucashPayment detail={detail?.payload} />
      case TransactionType.CANCEL_FROM_INCEPTION:
        return <CancelFromInception detail={detail?.payload} />
      case TransactionType.EXCESS_PREMIUM:
        return <ExcessPremium detail={detail?.payload} />
      case TransactionType.E_SERVICE_REGISTRATION:
        return <EServiceRegistration detail={detail?.payload} />
      case TransactionType.MATURITY_ADVANCE:
        return <MaturityAdvance detail={detail?.payload} />
      case TransactionType.PRUKID369:
        return <Prukid369 detail={detail?.payload} />
      case TransactionType.FULL_SURRENDER_REVERSAL:
        return <FullSurrenderReversal detail={detail?.payload} />
      case TransactionType.EXCELLENT_STUDENT_AWARD:
        return <ExcellentStudentAward detail={detail?.payload} />
      case TransactionType.CANCEL_RIDER:
        return <CancelRider detail={detail?.payload} />
      case TransactionType.SPECIAL_BONUS:
        return <SpecialBonus detail={detail?.payload} />
      case TransactionType.STOP_APL:
        return <StopAPL detail={detail?.payload} />
      case TransactionType.LOAN:
        return <LoanPayment detail={detail?.payload} />
      case TransactionType.PAID_UP:
        return <PaidUp detail={detail?.payload} />
      case TransactionType.CONFIRMATION_LETTER_OF_POLICY_ACCOUNT_AND_SURRENDER_VALUE:
      case TransactionType.POLICY_CERTIFICATE:
        return <PolicyCertificate detail={detail?.payload} />
      case TransactionType.BONUS_SURRENDER:
        return <BonusSurrender detail={detail?.payload} policyNum={detail?.policyNumber} />
      case TransactionType.FUND_SWITCHING:
        return <FundSwitching detail={detail?.payload} />
      case TransactionType.FATCA_DECLARATION:
        return <FATCADeclaration detail={detail?.payload} />
      case TransactionType.SCHEDULED_PAYMENT_STATEMENT:
        return <ScheduledPaymentStatement detail={detail?.payload} />
      case TransactionType.PREMIUM_REDIRECTION:
        return <PremiumRedirection detail={detail?.payload} />
      case TransactionType.COMPLAINT_AND_INQUIRY:
      case TransactionType.COMPLAINT:
      case TransactionType.INQUIRY:
        return (
          <InquiryComplaint
            detail={detail?.payload}
            form={inquiryComplaintForm}
            isHistory={isHistory}
            isInquiry={isInquiry}
            isSuspend={isSuspend}
            subTaskCode={subTaskCode}
            onSubmitInquiry={onSubmitInquiry}
            isQC={isQC}
            roles={roles}
            isNotCheckValidator={isNotCheckValidator}
            onCheckValidator={onCheckValidator}
            setUpdatedIACPayloadData={setUpdatedIACPayloadData}
            updatedIACPayloadData={updatedIACPayloadData as VeriPayloadData}
            isChangeTab={isChangeTab}
            setIsHiddenButtonsForIACHistory={setIsHiddenButtonsForIACHistory}
          />
        )
      case TransactionType.INHERITANCE:
        return <Inheritance detail={detail.payload}></Inheritance>

      case TransactionType.OTHER_MAJOR_ALTERATION:
      case TransactionType.OTHER_MINOR_ALTERATION:
      case TransactionType.OTHER_PAYOUT:
      case TransactionType.MAJOR_CHANGE_IN_FREE_LOOK:
      case TransactionType.MAJOR_CHANGE_AFTER_FREE_LOOK:
      case TransactionType.COMMON_REQUEST_FORM:
        return <CommonRequest detail={detail?.payload} />
      case TransactionType.PRODUCT_OPTION_SWITCHING:
        return <ProductOptionSwitching detail={detail?.payload} />
      case TransactionType.REDATING:
        return <VERedatingScreen detailData={detail.payload}></VERedatingScreen>
      case TransactionType.RIDER_ALTERATION:
        const riderAlterCommonRequest = detail?.payload.request

        return !!riderAlterCommonRequest ? (
          <CommonRequest detail={detail?.payload as TaskDetail.VeriCommonRequest} />
        ) : (
          <RiderAlteration
            detailData={detail.payload as TaskDetail.VERIRiderAlteration}
            policyNum={detail?.policyNumber}
          ></RiderAlteration>
        )
      case TransactionType.MAJOR_COMBINED_CHANGE:
        return <MajorCombinedChange detail={detail?.payload} policyNum={detail?.policyNumber}></MajorCombinedChange>
      case TransactionType.CASHLESS_O1:
      case TransactionType.CASHLESS_O2:
      case TransactionType.CASHLESS_DK:
      case TransactionType.CASHLESS_C9:
      case TransactionType.CASHLESS_P2:
      case TransactionType.CASHLESS_V2:
      case TransactionType.CASHLESS_M2:
      case TransactionType.CASHLESS_A9:
      case TransactionType.CASHLESS_S6:
      case TransactionType.CASHLESS_L3:
      case TransactionType.CASHLESS_D7:
      case TransactionType.CASHLESS_M3:
      case TransactionType.CASHLESS_V4:
      case TransactionType.CASHLESS_A0:
      case TransactionType.CASHLESS_I4:
      case TransactionType.CASHLESS_P9:
      case TransactionType.CASHLESS_I2:
      case TransactionType.CASHLESS_O3:
      case TransactionType.CASHLESS_A7:
      case TransactionType.CASHLESS_S1:
      case TransactionType.CASHLESS_P3:
      case TransactionType.CASHLESS_S2:
      case TransactionType.CASHLESS_B3:
      case TransactionType.CASHLESS_S3:
      case TransactionType.CASHLESS_C3:
      case TransactionType.CASHLESS_U3:
      case TransactionType.CASHLESS_V3:
      case TransactionType.CASHLESS_I3:
      case TransactionType.CASHLESS_C7:
      case TransactionType.CASHLESS_X1:
      case TransactionType.CASHLESS_T3:
      case TransactionType.CASHLESS_M9:
      case TransactionType.CASHLESS_D6:
      case TransactionType.CASHLESS_D8:
      case TransactionType.CASHLESS_D9:
      case TransactionType.CASHLESS_V5:
        return (
          <CashLess
            detail={detail.payload}
            isInquiry={isInquiry}
            isQC={isQC ?? false}
            processId={detail.processId}
            taskId={detail.id}
            isSuspend={isSuspend}
          />
        )
      case TransactionType.AGENT_ONBOARD_NEW:
      case TransactionType.AGENT_ONBOARD_REINS:
        return (
          <DsSevicesDetail
            detail={detail?.payload}
            isHistory={isHistory}
            basket={basket}
            setIsScroll={setIsScroll}
            isScroll={isScroll}
          />
        )
      case TransactionType.RIDER_REINSTATEMENT:
        return <RiderReinstatement detail={detail?.payload} policyNum={detail?.policyNumber}></RiderReinstatement>
      // case TransactionType.AGENT_UNIT_TRANSFER:
      // case TransactionType.AGENT_TRANSFER_POLICY:
      case TransactionType.AGENT_OFFICE_MOVING:
      case TransactionType.AGENT_TERMINATION_AG:
        return <DsAgentVerificationDetail detail={detail?.payload} />

      case TransactionType.JOURNAL_ALTERATION:
      case TransactionType.JOURNAL_PAYOUT:
        return <JournalScreen detail={detail?.payload} />

      case TransactionType.CHANGE_OCCUPATION_PERSONAL:
        return <ChangeOccupationPersonal detail={detail?.payload} />

      case TransactionType.CHANGE_DOB_GENDER:
        return <ChangeDOBGenderScreen detail={detail?.payload} />

      case TransactionType.CHANGE_OTHER_INFORMATION_PERSONAL:
        return <ChangeOtherInfomationPersonalScreen detail={detail?.payload} />
      default:
        return <Text> Transaction này chưa có giao diện</Text>
    }
  }

  return <>{renderDetail()}</>
}
