import { StyleSheet, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import React from 'react'
import { DrawerContentComponentProps, DrawerContentOptions } from '@react-navigation/drawer'
import { AppContext, assets, ErrorHandling, ModalComponent, Permission, RBAC } from '@pulseops/common'
import { pipe } from 'fp-ts/lib/function'
import { AdmintoolService, OBSharedStyles } from '@pulseops/outbound'
import { useTranslation } from 'react-i18next'
import { IBGeneralField, IBGeneralTable, TypeInputComponent } from '@pulseops/inbound'
import { useLoading } from '@mxt/zio-react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useIsFocused } from '@react-navigation/native'
import { ZIO } from '@mxt/zio'
import _ from 'lodash'
import moment from 'moment'

type AnswerForm = {
  id: string
  answerVN: string
  answerEN: string
  answerCode: string
}

interface Props extends DrawerContentComponentProps<DrawerContentOptions> { }

const FieldEdit = ({ form }: { form: UseFormReturn<AnswerForm, any> }) => {
  const { t } = useTranslation()
  return (
    <View style={{ paddingHorizontal: 15, paddingVertical: 10 }}>
      <IBGeneralField
        FieldForm={form}
        col={2}
        typeInput={[
          {
            type: TypeInputComponent.INPUT,
            formName: 'answerCode',
            title: t('Outbound:AdminTool:AnswerCode'),
            inputType: 'input',
            disabled: true
          },
          {
            type: TypeInputComponent.INPUT,
            formName: 'answerVN',
            title: t('Outbound:AdminTool:Answer_VN'),
            inputType: 'text',
            required: true,
            rules: {
              required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Answer_VN') }) }
            },
            maxLength: 500,
            expandRow: 2,
            multiline: true,
            numberOfLines: 3,
          },
          {
            type: TypeInputComponent.INPUT,
            formName: 'answerEN',
            title: t('Outbound:AdminTool:Answer_EN'),
            inputType: 'text',
            required: true,
            rules: {
              required: { value: true, message: t('message:OB0019', { field: t('Outbound:AdminTool:Answer_EN') }) }
            },
            maxLength: 500,
            expandRow: 2,
            multiline: true,
            numberOfLines: 3,
          }
        ]}
      ></IBGeneralField>
    </View>
  )
}

const AddPopup = ({
  isOpenAddPopup,
  setOpenAddPopup,
  form,
  setReloadFlag
}: {
  isOpenAddPopup: boolean
  setOpenAddPopup: (val: boolean) => void
  form: UseFormReturn<AnswerForm, any>
  setReloadFlag: (val: boolean) => void
}) => {
  const { t } = useTranslation()
  const [isLoading, bindLoading] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)

  const onAdd = form.handleSubmit((value) => {
    pipe(AdmintoolService.createA2S3Config({
      answerCode: value.answerCode,
      answerVN: value.answerVN,
      answerEN: value.answerEN
    }),
      ZIO.map((res) => {
        showToast(t('message:OB0113'), "success")
        setReloadFlag(true)
        setOpenAddPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })

  return (
    <ModalComponent
      children={<FieldEdit form={form} />}
      visible={isOpenAddPopup}
      onClose={() => setOpenAddPopup(false)}
      title={t('Outbound:AdminTool:CreateNew')}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          action: () => setOpenAddPopup(false),
          disabled: isLoading,
          loading: false,
          onlyWide: false,
          style: { height: 35, marginRight: 10 }
        },
        {
          text: t('common:Add'),
          type: 'filled',
          action: () => onAdd(),
          disabled: isLoading,
          loading: false,
          onlyWide: false,
          style: { height: 35 }
        }
      ]}
      centerTitle
      modalWidth={800}
    />
  )
}

const EditPopup = ({
  isOpenEditPopup,
  setOpenEditPopup,
  form,
  setReloadFlag
}: {
  isOpenEditPopup: boolean
  setOpenEditPopup: (val: boolean) => void
  form: UseFormReturn<AnswerForm, any>
  setReloadFlag: (val: boolean) => void
}) => {
  const { t } = useTranslation()
  const [isLoading, bindLoading] = useLoading(false)
  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const [defaultData, setDefaultData] = React.useState<AnswerForm>()

  React.useEffect(() => {
    if (form.watch().id && !defaultData) {
      setDefaultData(form.watch())
    }
  }, [form.watch()])

  const onSave = form.handleSubmit((value) => {
    pipe(AdmintoolService.updateA2S3Config({
      id: value.id,
      answerCode: value.answerCode,
      answerVN: value.answerVN,
      answerEN: value.answerEN
    }),
      ZIO.map((res) => {
        showToast(t('message:OB0040'), "success")
        setReloadFlag(true)
        setOpenEditPopup(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({})
    )
  })
  return (
    <ModalComponent
      children={<FieldEdit form={form} />}
      visible={isOpenEditPopup}
      onClose={() => setOpenEditPopup(false)}
      title={t('Outbound:AdminTool:Edit')}
      actions={[
        {
          text: t('common:Cancel'),
          type: 'outline',
          action: () => setOpenEditPopup(false),
          disabled: isLoading,
          loading: false,
          onlyWide: false,
          style: { height: 35, marginRight: 10 }
        },
        {
          text: t('common:Save'),
          type: 'filled',
          action: () => onSave(),
          disabled: isLoading || _.isEqual(defaultData, form.watch()),
          loading: false,
          onlyWide: false,
          style: { height: 35 }
        }
      ]}
      centerTitle
      modalWidth={800}
    />
  )
}

const AddButton = ({
  setOpenAddPopup,
  form,
  newAnswerCode
}: {
  setOpenAddPopup: (val: boolean) => void
  form: UseFormReturn<AnswerForm, any>
  newAnswerCode: number
}) => {
  const { t } = useTranslation()
  return (
    <View style={{ justifyContent: 'flex-end', flexDirection: 'row', paddingBottom: 10 }}>
      <TouchableOpacity
        onPress={() => {
          setOpenAddPopup(true)
          form.reset()
          form.setValue("id", "")
          form.setValue("answerCode", `A${newAnswerCode}`)
          form.setValue("answerVN", "")
          form.setValue("answerEN", "")
        }}
      >
        <View style={[OBSharedStyles.actionContent, OBSharedStyles.actionHightLight]}>
          <Text style={OBSharedStyles.actionHightLightText}>{t('Outbound:AdminTool:AddButton')}</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const TableConfig = ({
  roles,
  setOpenEditPopup,
  data,
  form
}: {
  roles: string[]
  setOpenEditPopup: (val: boolean) => void
  data: { id: string, order: number, answerCode: string, answerVN: string, answerEN: string, updatedDate: string | null }[]
  form: UseFormReturn<AnswerForm, any>
}) => {
  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()

  const filterColumnList = {
    answerCode: '',
    answerVN: '',
    answerEN: ''
  }

  const columns = [
    {
      label: t('Outbound:AdminTool:No'),
      field: 'order',
    },
    {
      label: t('Outbound:AdminTool:AnswerCode'),
      field: 'answerCode'
    },
    {
      label: t('Outbound:AdminTool:Answer_VN'),
      field: 'answerVN',
      render: (value: string) => {
        return <View style={{ width: 350 }}><Text>{value}</Text></View>
      }
    },
    {
      label: t('Outbound:AdminTool:Answer_EN'),
      field: 'answerEN',
      render: (value: string) => {
        return <View style={{ width: 350 }}><Text>{value}</Text></View>
      }
    },
    {
      label: t('Outbound:AdminTool:UpdatedDate'),
      field: 'updatedDate',
      format: "date"
    },
    {
      label: '',
      field: 'answerCode',
      disabled: true,
      hideFilterCol: true,
      render: (value: string) => {
        return <TouchableOpacity
          onPress={() => {
            setOpenEditPopup(true)
            const answer = data.find(x => x.answerCode === value)
            form.reset()
            form.setValue("id", answer?.id || "")
            form.setValue("answerCode", answer?.answerCode || "")
            form.setValue("answerVN", answer?.answerVN || "")
            form.setValue("answerEN", answer?.answerEN || "")
          }}
        >
          <assets.OBPencilGray />
        </TouchableOpacity>

      }
    }
  ]


  return (
    <View style={{ flexDirection: 'row' }}>
      <IBGeneralTable
        dataTable={columns}
        data={data}
        maxHeight={height - height * 0.25}
        maxWidth={width - width * 0.025}
        maxWidthContainer
        filterColumn={filterColumnList}
        autoPaging
      ></IBGeneralTable>
    </View>
  )
}

export const ConfigA2S3AnswersListScreen = ({ navigation }: Props) => {
  const [isOpenAddPopup, setOpenAddPopup] = React.useState<boolean>(false)
  const [isOpenEditPopup, setOpenEditPopup] = React.useState<boolean>(false)
  const [isLoading, bindLoading] = useLoading(false)
  const [data, setData] = React.useState<{ id: string, order: number, answerCode: string, answerVN: string, answerEN: string, updatedDate: string | null }[]>([])
  const roles: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))
  const isFocused = useIsFocused()
  const { showGlobalLoading, showToast, changeBreadcrumb } = React.useContext(AppContext.AppContextInstance)
  const [isReloadFlag, setReloadFlag] = React.useState<boolean>(false)
  const { t } = useTranslation()

  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  const loadingData = () => {
    pipe(AdmintoolService.getA2S3Config(),
      ZIO.map((res) => {
        setData(_.orderBy(res, "answerCode", "asc").map((x, i) => ({
          order: i + 1,
          answerCode: x.answerCode || "",
          answerVN: x.answerVN || "",
          answerEN: x.answerEN || "",
          updatedDate: moment(x.createdDate).format("YYYYMMDD"),
          id: x.id || ""
        })))
        setReloadFlag(false)
        return ZIO.unit
      }),
      bindLoading,
      ZIO.unsafeRun({}))
  }

  React.useEffect(() => {
    loadingData()
  }, [])

  React.useEffect(() => {
    if (isReloadFlag) {
      loadingData()
      form.reset()
    }
  }, [isReloadFlag])

  React.useEffect(() => {
    if (isFocused) {
      changeBreadcrumb([
        {
          title: '',
          navigate: () => {
            navigation.navigate('HomeScreen')
          }
        },
        { title: t('menu:Settings'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen' }) },
        { title: t('Setting:AdminTool'), navigate: () => navigation.navigate('SettingStack', { screen: 'SettingScreen', params: { tabTitle: 'adminTool' } }) },
        { title: t('Outbound:AdminTool:A2S3'), navigate: null }
      ])
    }

  }, [isFocused])

  const form = useForm<AnswerForm>({
    defaultValues: {
      id: '',
      answerEN: '',
      answerVN: '',
      answerCode: "",
    }
  })

  return (
    <View style={styles.container}>
      <>
        <AddButton setOpenAddPopup={setOpenAddPopup} form={form} newAnswerCode={data.length + 1} />
        <TableConfig roles={roles} setOpenEditPopup={setOpenEditPopup} data={data} form={form} />
        {isOpenAddPopup &&
          <AddPopup isOpenAddPopup={isOpenAddPopup} setOpenAddPopup={setOpenAddPopup} form={form} setReloadFlag={setReloadFlag} />
        }
        {isOpenEditPopup &&
          <EditPopup isOpenEditPopup={isOpenEditPopup} setOpenEditPopup={setOpenEditPopup} form={form} setReloadFlag={setReloadFlag} />
        }
      </>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: '#FFFFFF',
    height: "100%"
  },
  header: {
    color: '#000000',
    fontSize: 24,
    fontWeight: 'bold'
  },
  addButton: {
    color: '#FFFFFF',
    backgroundColor: '#ED1B2E',
    flexDirection: 'row',
    borderRadius: 8
  }
})
