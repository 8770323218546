/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable prefer-const */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-case-declarations */
/* eslint-disable no-fallthrough */
import React from 'react'
import { View, StyleSheet } from 'react-native'
import {
  Tab,
  AppContext,
  TaskDetail,
  RoundedButton,
  Toast,
  TabList,
  form2,
  ErrorHandling,
  TransactionType,
  RBAC,
  TASK_DETAIL_ROLES,
  StructureService,
  SubTaskType,
  SourceType
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { useLoading } from '@mxt/zio-react'
import { GeneralInfoScreen } from './da-general-info/da-general-view'
import { DaGeneralInfoService, GeneralForm } from './da-general-info'
import { DAtrucTure, StructureForm } from './da-structure'
import { DAdocumentsScreen, DocumentForm } from './da-documents'
import { AgentEvaluationForm, DAagentEvaluationScreen, DaAgentService } from './da-agent-evaluation'
import { Color } from '@material-ui/lab'
import { GeneralInformationSelect } from './mock'
import { pipe } from 'fp-ts/lib/function'
import { ZIO } from '@mxt/zio'
import { DAContext } from './da-context-general'
import { DaDocumentService } from './da-documents/da-doccument-services'
import { useWatch } from 'react-hook-form'
import { TaskDetailState } from '../../state'
type Props = {
  detail: TaskDetail.AgentOnboardNew
  isHistory: boolean | null
  basket: SubTaskType
  setIsScroll?: (ischeck: boolean) => void
  isScroll?: boolean
}
const enum SalesType {
  AGENCY = 'AGENCY',
  GALLERIE = 'GALLERIE',
  MILLENNIAL = 'MILLENNIAL',
  PTD = 'PTD',
  DSR = 'DSR'
}
const enum SubChannel {
  FSC = 'FSC',
  TMR = 'TMR',
  QAS = 'QAS'
}

const setChannelLinkByPRUforce = (detail: TaskDetail.AgentOnboardNew): string => {
  switch (detail.transactionType) {
    case TransactionType.AGENT_ONBOARD_NEW:
      if (_.isNil(detail.agentCode)) {
        return '2.1'
      }
    case TransactionType.AGENT_ONBOARD_REINS:
      const checkTerDate = moment().diff(moment(detail.terdate, 'DD/MM/YYYY'), 'month', true) >= 6
      const terReason = 'ADPER'
      const dummyUnit = ''
      if (checkTerDate || terReason !== 'ADPER' || detail.oldUnit === detail.newUnit || detail.oldUnit === dummyUnit) {
        return '2.2'
      } else {
        return '2.3'
      }
    default:
      return '-'
  }
}

const setChannelLinkByPRUdaily = (detail: TaskDetail.AgentOnboardNew): string => {
  const checkTerDate = moment().diff(moment(detail.terdate, 'DD/MM/YYYY'), 'month', true)
  const terReason = 'ADPER'
  const dummyUnit = ''
  switch (detail.salesType) {
    case SalesType.AGENCY:
      if (_.isNil(detail.agentCode)) {
        return '1.1'
      } else {
        if (
          checkTerDate >= 6 ||
          terReason !== 'ADPER' ||
          detail.oldUnit === detail.newUnit ||
          detail.oldUnit === dummyUnit
        ) {
          return '1.2'
        } else {
          return '1.3'
        }
      }
    case SalesType.GALLERIE:
      if (_.isNil(detail.agentCode)) {
        return '1.4'
      } else {
        if (checkTerDate >= 12) {
          return '1.5'
        } else {
          return '1.6'
        }
      }
    case SalesType.MILLENNIAL:
      if (_.isNil(detail.agentCode)) {
        return '1.7'
      } else {
        if (
          checkTerDate >= 6 ||
          terReason !== 'ADPER' ||
          detail.oldUnit === detail.newUnit ||
          detail.oldUnit === dummyUnit
        ) {
          return '1.8'
        } else {
          return '1.9'
        }
      }
    case SalesType.PTD:
      if (_.isNil(detail.agentCode)) {
        return '1.10'
      } else {
        if (checkTerDate < 3) {
          return '1.11'
        } else {
          return '1.12'
        }
      }
    case SalesType.DSR:
      switch (detail.subChanel) {
        case SubChannel.FSC:
          if (_.isNil(detail.agentCode)) {
            return '1.13'
          } else {
            if (checkTerDate < 3) {
              return '1.14'
            } else {
              return '1.15'
            }
          }
        case SubChannel.TMR:
          if (_.isNil(detail.agentCode)) {
            return '1.16'
          } else {
            if (checkTerDate < 3) {
              return '1.17'
            } else {
              return '1.18'
            }
          }
        case SubChannel.QAS:
          if (_.isNil(detail.agentCode)) {
            return '1.19'
          } else {
            if (checkTerDate < 3) {
              return '1.20'
            } else {
              return '1.21'
            }
          }
        default:
          return '-'
      }
    default:
      return '-'
  }
}

const DEFAULT_QSCORE = {
  age: 0,
  eduacation: 0,
  work: 0,
  customer: 0
}

export function DsSevicesDetail(props: Props) {
  const { t, i18n } = useTranslation()
  const [isLoading, bindLoader] = useLoading(false)
  const [totalScore, setTotalScore] = React.useState<number>(0)

  const sumAge = React.useMemo(() => {
    const newDate = new Date()
    const ageDage = dateofBirth ? moment(dateofBirth, 'DD/MM/yyyy') : moment(props.detail.dob, 'DD/MM/yyyy')
    return moment(newDate).diff(ageDage, 'year')
  }, [])
  const [subTabIndex, setSubTabIndex] = React.useState<number>(0)
  const statusOptions = [
    { label: t('Blank'), value: 'blank' },
    { label: t('Done'), value: 'done' },
    { label: t('Reject'), value: 'reject' },
    { label: t('Lackdoc'), value: 'lackdoc' }
  ]
  const [data, setData] = React.useState<Array<DaDocumentService.DetaiList>>([])

  const { ChannelDocument } = pipe(
    DaDocumentService.getDocumentLog(props.detail.businessKey ?? ''),
    ZIO.flatMap((resLog) =>
      !!resLog && !!resLog.detailList && resLog.detailList.length > 0
        ? ZIO.succeed(resLog.detailList)
        : pipe(
            DaDocumentService.getChannelDocument({
              source: props.detail.source,
              channelId: generalInfo.base.getValues('channelLink') ?? '',
              // getValues('generalForm.channelLink') ?? '',
              idNo: props.detail.idNo
            }),
            ZIO.map((res) => {
              return res.data
            })
          )
    ),
    bindLoader,
    ZIO.map((res) => {
      mappingDocumentData(res)
      return {
        ChannelDocument: res
      }
    }),
    ErrorHandling.runDidMount({
      ChannelDocument: []
    })
  )

  React.useEffect(() => {
    if (ChannelDocument.length > 0) {
      setData(ChannelDocument)
    }
  }, [ChannelDocument])
  const mappingDocumentData = (data: Array<DaDocumentService.DetaiList>) => {
    data.map((item: DaDocumentService.DetaiList, i: number) => {
      !!item.status
        ? docCument.base.setValue(`documentList.${i}.status`, getStatus(item.status))
        : docCument.base.setValue(`documentList.${i}.status`, statusOptions[0])
    })
  }

  const getStatus = (value: string) => {
    if (value) {
      return statusOptions.find((x) => x.value === value) ?? statusOptions[0]
    }
    return statusOptions[0]
  }

  const setSubChannel = () => {
    switch (props.detail.salesType) {
      case 'PTD':
        if (props.detail.subChanel === 'FSC' && props.detail.agCAT !== 'QAS') {
          return 'FSC'
        } else if (props.detail.subChanel === 'TMR') {
          return 'TMR'
        } else if (props.detail.subChanel === 'FSC' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        }
      case 'DSR':
        if (props.detail.subChanel === 'FSC' && props.detail.agCAT !== 'QAS') {
          return 'FSC'
        } else if (props.detail.subChanel === 'TMR') {
          return 'TMR'
        } else if (props.detail.subChanel === 'FSC' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        } else if (props.detail.subChanel === 'QAS' && props.detail.agCAT === 'QAS') {
          return 'QAS'
        }
      default:
        return '-'
    }
  }
  const defaultValuesAgent: AgentEvaluationForm.Raw = {
    qsCoreCheck: false,
    qualifiedCheck: false,
    candidateCheck: false,
    certificateCheck: false,
    sumAge: sumAge,
    Educations: null,
    workExperiences: '',
    hasAListOfPotentialCustomers: props.detail.hasAListOfPotentialCustomers ?? '',
    total: totalScore,
    nameSystem: '',
    nameApplication: '',
    DateOfbirthSys: '',
    DateOfbirthApp: '',
    MOFnumber: '',
    MOFdate: '',
    ExamCode: '',
    sumAgeCandidate: sumAge,
    isSaveAgent: false
  }
  const defaultValuesGeneral: GeneralForm.Raw = {
    qsCore: totalScore,
    subChannel: setSubChannel() ?? '',
    salesType: props.detail.salesType ?? '',
    eREF: props.detail.eREF ?? '',
    channelLink:
      props.detail.source === 'PRUFORCE'
        ? setChannelLinkByPRUforce(props.detail)
        : setChannelLinkByPRUdaily(props.detail),
    agCode: props.detail.agentCode ?? '',
    leadercodedescription: !props.detail.newLeaderName
      ? props.detail.leaderCodeDescription ?? ''
      : props.detail.newLeaderName,
    officecode: props.detail.officeCode ?? '',
    saleZone: props.detail.saleZone ?? '',
    regioncode: props.detail.regionCode ?? '',
    regiondescription: props.detail.regionDescription ?? '',
    leaderCode: !!props.detail.newLeaderCode ? props.detail.newLeaderCode : props.detail.leaderCode ?? '',
    terminatedDate: ' ',
    erminationReason: ' ',
    lastName: !!props.detail.lastName ? props.detail.lastName : null,
    firstName: !!props.detail.firstName ? props.detail.firstName : null,
    genDer: !!props.detail.genderCode
      ? GeneralInformationSelect.getGenderOption(props.detail.genderCode ?? '')(i18n.language)
      : null,
    oldidNo: props.detail.oldIdNo ?? '',
    dateofBirth: !!props.detail.dob ? new Date(props.detail.dob) : null,
    idDate: new Date(props.detail.idDate ?? ''),
    idPlace: props.detail.idPlace ?? '',
    idExpireddate: !!props.detail.idExpiredDate ? new Date(props.detail.idExpiredDate) : null,
    placeofBirth: props.detail.placeOfBirth ?? '',
    nationality: null,
    ethNic: props.detail.ethNic ?? '',
    agentcodeRefer: props.detail.agentCodeRefer ?? '',
    agentnameRefer: props.detail.agentNameRefer ?? '',
    phonenumber: props.detail.phoneNumber ?? '',
    companyphone: props.detail.companyPhone ?? '',
    HomePhone: props.detail.homePhone ?? '',
    Email: props.detail.email ?? '',
    Zalo: props.detail.zalo ?? '',
    StreetResidential: props.detail.address ?? '',
    DistrictResidential: null,
    WardResidential: null,
    locationCodeResidential: props.detail.locationCode ?? '',
    ProvinceResidential: null,
    ProvinceAllocated: null,
    DistrictAllocated: null,
    WardAllocated: null,
    locationCodeContact: props.detail.contactlocationCode ?? '',
    StreetAllocated: props.detail.Allocatedaddress ?? '',
    AllocatedlocationCode: props.detail.AllocatedlocationCode ?? '',
    ProvinceContact: null,
    DistrictContact: null,
    WardContact: null,
    StreetContact: props.detail.contactaddress ?? '',
    BankName: null,
    Bankbranchname: null,
    Bankcode: props.detail.bankCode ?? '',
    Bankaccount: props.detail.bankAccount ?? '',
    BanknameOther: null,
    BankbranchnameOther: null,
    Education: !!props.detail.educationCode
      ? GeneralInformationSelect.getEducationOption(props.detail.educationCode ?? '')(i18n.language)
      : null,
    Specialization: props.detail.specialization ?? '',
    Occupation: null,
    Income: GeneralInformationSelect.getIncomeOption(props.detail.income ?? '')(i18n.language),
    Taxcode: props.detail.taxCode ?? '',
    Maritalstatus: !!props.detail.marriedCode
      ? GeneralInformationSelect.getMaritalOption(props.detail.marriedCode ?? '')(i18n.language)
      : null,
    Numberofchildren: props.detail.numberOfChildren ?? '',
    checkDuplicate: false,
    newLeaderName: props.detail.newLeaderName ?? '',
    newLeaderCode: props.detail.newLeaderCode ?? '',
    checkPhone: false,
    checkBank: false,
    isSaveGeral: false,
    assessByDesc: props.detail.assessByDesc ?? '',
    assessmentDate: props.detail.asessmentDate ?? '',
    reAssess: props.detail.reAssess ?? '',
    reAssessDate: props.detail.reAssessDate ?? '',
    role: props.detail.role ?? '',
    status: props.detail.status ?? false,
    checkActiveAgent: false
  }
  const defaultValuesStructure: StructureForm.Raw = {
    saleZone: '',
    gaType: '',
    agentType: 'AG',
    officeCode1: '',
    gaCode1: '',
    regionCode: '',
    officeCode2: '',
    gaCode2: '',
    serviceBranch: '',
    galleries: '',
    agentCategory: '', // Huy QC cf
    ref: props.detail.ref ?? '',
    isSaveStructure: false
  }

  const { getFooter, showGlobalLoading } = React.useContext(AppContext.AppContextInstance)
  const agentEvaluation = form2.useForm(AgentEvaluationForm.codec, { defaultValues: defaultValuesAgent })
  const generalInfo = form2.useForm(GeneralForm.codec, { defaultValues: defaultValuesGeneral })
  const docCument = form2.useForm(DocumentForm.codec, {
    defaultValues: {
      documentList: []
    }
  })
  const strucTure = form2.useForm(StructureForm.codec, { defaultValues: defaultValuesStructure })
  const dateofBirth = useWatch({ control: generalInfo.base.control, name: 'dateofBirth' })
  const [toast, setToast] = React.useState<{
    open: boolean
    message: string
    type?: Color
  }>({ open: false, message: '' })
  const hasTab = (tabId: number, tabs: Tab[]) => {
    return tabs.find((item) => item.id === tabId) !== undefined
  }
  const agentGeneralInfo = pipe(
    DaGeneralInfoService.getGeneralInfoLog(props.detail.businessKey ?? ''),
    ZIO.map((res) => res.data),
    ErrorHandling.runDidMount()
  )

  const getStructure = pipe(
    StructureService.getStructureLog(props.detail.businessKey ?? ''),
    ZIO.map((res) => res),
    ErrorHandling.runDidMount()
  )
  const getAgentEvaluation = pipe(
    DaAgentService.getAgentEvaluation(props.detail.businessKey ?? ''),
    ZIO.map((res) => res),
    ErrorHandling.runDidMount()
  )
  React.useEffect(() => {
    showGlobalLoading(isLoading)
  }, [isLoading])

  React.useEffect(() => {
    pipe(TaskDetailState.action.reset(), ZIO.unsafeRun({}))
  }, [])

  const totalValid = React.useMemo(() => {
    return generalInfo.base.formState.isValid && strucTure.base.formState.isValid
  }, [generalInfo.base.formState.isValid, strucTure.base.formState.isValid])

  const checkActiveAgent = useWatch({ control: generalInfo.base.control, name: 'checkActiveAgent' })
  const checkPhone = useWatch({ control: generalInfo.base.control, name: 'checkPhone' })
  const checkBank = useWatch({ control: generalInfo.base.control, name: 'checkBank' })
  const newDate = moment().valueOf()
  const idExpiredDate = moment(generalInfo.base.getValues('idExpireddate')).valueOf()
  const candidateCheck = useWatch({ control: agentEvaluation.base.control, name: 'candidateCheck' })
  const qsCoreCheck = useWatch({ control: agentEvaluation.base.control, name: 'qsCoreCheck' })
  const documentList = useWatch({ control: docCument.base.control, name: 'documentList' })
  const [isSaveGenral, setIsSaveGenal] = React.useState<boolean>(false)
  const [isSaveStructure, setIsSaveStructure] = React.useState<boolean>(false)
  const [isSaveAgent, setIsSaveAgent] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!!getAgentEvaluation) {
      setIsSaveAgent(getAgentEvaluation.data === null ? false : true)
      !!getAgentEvaluation?.data?.qsCoreCheck
        ? agentEvaluation.base.setValue('qsCoreCheck', getAgentEvaluation?.data?.qsCoreCheck)
        : ''
      !!getAgentEvaluation?.data?.candidateCheck
        ? agentEvaluation.base.setValue('candidateCheck', getAgentEvaluation?.data?.candidateCheck)
        : ''
    }
    if (!!getStructure) {
      setIsSaveStructure(getStructure.data === null ? false : true)
      !!getStructure.data?.status
        ? strucTure.base.setValue('isSaveStructure', JSON.parse(getStructure.data?.status))
        : ''
    }
    if (!!agentGeneralInfo) {
      setIsSaveGenal(agentGeneralInfo === null ? false : true)
      !!agentGeneralInfo?.status ? generalInfo.base.setValue('isSaveGeral', JSON.parse(agentGeneralInfo?.status)) : ''
    }
  }, [getAgentEvaluation, getStructure, agentGeneralInfo])
  React.useEffect(() => {
    pipe(
      ZIO.succeed(totalValid),
      ZIO.tap((valid) => {
        if (!isSaveAgent || (!valid && !isSaveGenral) || !isSaveStructure) {
          return TaskDetailState.action.setFormState(false, t('message:MS100002'))
        } else if (checkBank === true) {
          return TaskDetailState.action.setFormState(false, t('message:MS160008'))
        } else if (checkPhone === true) {
          return TaskDetailState.action.setFormState(false, t('message:MS160007'))
        } else if (documentList.find((item) => item.status?.value === 'reject')) {
          return TaskDetailState.action.setFormState(false, t('message:MS160003'))
        } else if (sumAge < 21 && sumAge > 60) {
          return TaskDetailState.action.setFormState(false, t('message:MS160004'))
        } else if (candidateCheck === false) {
          return TaskDetailState.action.setFormState(false, t('message:MS160004'))
        } else if (checkActiveAgent === true) {
          return TaskDetailState.action.setFormState(false, t('RequestInfo:ActiveAgent'))
        } else if (totalScore < 1) {
          return TaskDetailState.action.setFormState(false, t('message:MS160005'))
        } else if (qsCoreCheck === false) {
          return TaskDetailState.action.setFormState(false, t('message:MS160006'))
        } else if (newDate >= idExpiredDate) {
          return TaskDetailState.action.setFormState(false, t('message:MS160011'))
        } else {
          return TaskDetailState.action.setFormState(true)
        }
      }),
      ErrorHandling.run({})
    )
  }, [
    totalValid,
    sumAge,
    checkBank,
    checkPhone,
    documentList,
    candidateCheck,
    checkActiveAgent,
    qsCoreCheck,
    agentGeneralInfo,
    getStructure,
    totalScore,
    getAgentEvaluation,
    isSaveAgent,
    isSaveGenral,
    isSaveStructure
  ])

  const RequestTabs = React.useMemo(() => {
    return [
      {
        id: 0,
        title: t('RequestInfo:GeneralInformation'),
        permission: ''
      },
      {
        id: 1,
        title: t('RequestInfo:Documents'),
        permission: ''
      },
      {
        id: 2,
        title: t('RequestInfo:Structure'),
        permission: ''
      },
      {
        id: 3,
        title: t('RequestInfo:AgentEvaluation'),
        permission: ''
      }
    ]
  }, [])

  const eduCation = useWatch({ control: generalInfo?.base.control, name: 'Education' })?.value
  const Educations = React.useMemo(() => {
    return GeneralInformationSelect.EducationData.find((item) => item.code === eduCation)
  }, [])
  const { count } = React.useMemo(() => {
    let score = 0
    let qScore = DEFAULT_QSCORE

    let arrEducations = ['TH', 'CD', 'DH', 'CH']
    if (sumAge >= 21 && sumAge <= 45) {
      score += 1
      qScore = { ...qScore, age: 1 }
    }
    if (arrEducations.includes(Educations?.code ?? '')) {
      score += 1
      qScore = { ...qScore, eduacation: 1 }
    }

    if (data.find((item) => item.docID === '20102161' && item.status === 'done')) {
      score += 1
      qScore = { ...qScore, work: 1 }
    }
    if (
      (props.detail.source === SourceType.PRUDAILY &&
        data.find((item) => item.docID === '20102181' && item.status === 'done')) ||
      props.detail.source === SourceType.PRUFORCE
    ) {
      score += 1
      qScore = { ...qScore, customer: 1 }
    }
    setTotalScore(score)
    return {
      qscore: qScore,
      count: score
    }
  }, [Educations, sumAge, data]) ?? { count: 0, qscore: DEFAULT_QSCORE }

  return (
    <View style={{ flex: 1 }}>
      <DAContext.DAGeneralContextInstance.Provider
        value={{
          point: count
        }}
      >
        <TabList
          gutterBottom
          isSubTab={true}
          useOriginalId={true}
          menus={RequestTabs}
          tabIndex={subTabIndex}
          onChangeTab={(index) => {
            getFooter(null)
            setSubTabIndex(index)
          }}
        />
        <View>
          {subTabIndex === 0 && hasTab(0, RequestTabs) && (
            <GeneralInfoScreen
              form={generalInfo.base}
              detail={props.detail}
              isHistory={props.isHistory}
              setStatusSave={setIsSaveGenal}
              checkSubTabIndex={setSubTabIndex}
              setIsScroll={props?.setIsScroll}
              isScroll={props?.isScroll}
            />
          )}
          {subTabIndex === 1 && hasTab(1, RequestTabs) && (
            <DAdocumentsScreen
              detail={props.detail}
              form={docCument.base}
              formGeneral={generalInfo.base}
              isHistory={props.isHistory}
              checkSubTabIndex={setSubTabIndex}
              setIsScroll={props?.setIsScroll}
              isScroll={props?.isScroll}
            />
          )}
          {subTabIndex === 2 && hasTab(2, RequestTabs) && (
            <DAtrucTure
              detail={props.detail}
              form={strucTure.base}
              formGeneral={generalInfo.base}
              isHistory={props.isHistory}
              setStatusSave={setIsSaveStructure}
              checkSubTabIndex={setSubTabIndex}
              setIsScroll={props?.setIsScroll}
              isScroll={props?.isScroll}
            />
          )}
          {subTabIndex === 3 && hasTab(3, RequestTabs) && (
            <DAagentEvaluationScreen
              structureForm={strucTure.base}
              form={agentEvaluation.base}
              generalForm={generalInfo.base}
              detail={props.detail}
              isHistory={props.isHistory}
              setStatusSave={setIsSaveAgent}
            />
          )}
        </View>

        <Toast
          message={toast.message}
          visible={toast.open}
          type={toast.type}
          onClose={() => setToast({ open: false, message: '', type: undefined })}
        />
      </DAContext.DAGeneralContextInstance.Provider>
    </View>
  )
}

export const Footer = (props: {
  isLoading: boolean
  onReset: () => void
  onSubmit: () => void
  disableSave?: boolean
}) => {
  const { isLoading, onReset, onSubmit, disableSave } = props
  const { t } = useTranslation()
  const permissions: string[] = pipe(RBAC.permissions, ErrorHandling.runDidMount([]))

  return (
    <View>
      {permissions.includes(TASK_DETAIL_ROLES.EditRequestInfoDS) && (
        <View style={styles.row}>
          <RoundedButton
            showBorder={true}
            filled={false}
            activeOpacity={0.7}
            text={t('common:Cancel')}
            textStyle={styles.btnTitle}
            style={{ height: 39, marginEnd: 15, paddingHorizontal: 20 }}
            textColorDisable="#B0B0B0"
            textColorEnable="#ED1B2E"
            bgColorDisable={'#B0B0B0'}
            bgColorEnable={'#ED1B2E'}
            borderColorDisable={'#B0B0B0'}
            borderColorEnable={'#ED1B2E'}
            onPress={onReset}
          />
          <RoundedButton
            isLoading={isLoading}
            showBorder={false}
            filled={true}
            activeOpacity={0.7}
            text={t('common:Save')}
            textStyle={styles.btnTitle}
            style={{ height: 39, paddingHorizontal: 20 }}
            textColorDisable="#FFFFFF"
            textColorEnable="#FFFFFF"
            bgColorDisable={'#B0B0B0'}
            bgColorEnable={'#ED1B2E'}
            borderColorDisable={'#B0B0B0'}
            borderColorEnable={'#ED1B2E'}
            onPress={onSubmit}
            disabled={disableSave}
          />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 40
  },
  btnTitle: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 29
  }
})
