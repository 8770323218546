import { pipe } from 'fp-ts/lib/function'
import {
  AuthService,
  GeneralService,
  POApi,
  PartialWithdrawal,
  replaceSpecialCharactersInFileName
} from '@pulseops/common'
import { ZIO, Task } from '@mxt/zio'
import randomBytes from 'randombytes'
import {
  PolicySearchResponse,
  PolicyInfoC,
  ProductTypeC,
  PolicyValueC,
  extraInfoC,
  ExtraInfoResponse,
  PremiumHisReq,
  PremiumHistoryInfo,
  BillingFreqRes,
  dataBillingFreq,
  PruCashBenefitReq,
  PruCashBenefitDataInfo,
  BonusReverseInfoC,
  BonusReverseDataC,
  PremiumFrequencyDetailResponseC,
  APLValueResponseC,
  OPLValueResponseC,
  BonusValueResponseC,
  PrucashBenefitsResponseC,
  SurrenderValueResponseC,
  ContractValueResponseC,
  ClientSearch,
  ClientSearchResponse,
  CustomerProfileResponse,
  PolicySummaryResponse,
  DuplicatePhoneResponse,
  PolicysSearch,
  PolicyInquiryCountResponse,
  AgentInfoResponse,
  FAOInfoResponse,
  ReinResponse,
  ClientInsuranceInfo,
  ProductResponse,
  RelatedDocumentInfo,
  ClaimPaymentDetailInfo,
  InvestmentSummaryResponse,
  InvestmentFundResponse,
  PartialWithdrawResponse,
  PartialWithdrawInfoRequest,
  DetailFundValueResponse,
  ACMVQueryResponse,
  UnitLinkFundPriceResponse,
  MainProductResponse,
  TopUpHistoryResponse,
  TopUpHistoryDetailResponse,
  FundSwitchingDetailResponse,
  PremiumRedirectionHistoryResponse,
  FundValueUnitResponse,
  PartialWithdrawHistoryResponse,
  AccountValueInfoResponse,
  AccountValueDetailResponse,
  LoanRepayInquiryResponse,
  PaidUpInquiryResponse,
  DraftTransactionSearch,
  DraftTransactionResponse,
  SaveDraft,
  SaveDraftResponse,
  ChangePOHistoryResponse,
  PolicyNoteWarningResponse,
  CheckNoteInformationResponse,
  PolicyNoteDetailResponse,
  OfficeCode,
  NBUWInfoResponse,
  NoteNBUWResponse,
  PaymentListResponse,
  PaymentInfoDetailResponse,
  PrurewardAgentResponse,
  PrurewardPointHistoryResponse,
  PrurewardTierHistoryResponse,
  PrurewardRedeemCodeHistoryResponse,
  PrurewardGiftRedemptionHistoryResponse,
  PrurewardCustomerNoteResponse,
  PrurewardCustomerInfoResponse,
  PrurewardsSummaryResponse,
  LasTransactionHistoryResponse,
  MasterProductsResponse,
  PremiumPaymentHistoryResponse,
  SearchClaimResponse,
  SearchClaimInquiry,
  ClaimCaseDetail,
  PendingNoticeData,
  MedicalInfoData,
  PolicyRiderData,
  BenefitItemData,
  DocumentsClaimData,
  PayModeData,
  PaymentAllocationData,
  ReceiptsDataList,
  MainProductsResponse,
  A15CharacterResponse,
  LastestCustomerBehaviorResponse,
  CustomerBehaviorData,
  LastestHistoryCustomerBehaviorResponse,
  ExtensionNumberReponse,
  MakeCallResponse,
  CallbackAppointmentSearch,
  CallbackAppointmentResponse,
  DocsResponse,
  DocsClaimAdvanceResponse,
  ClaimLetterResponse,
  A14S0Response,
  A14S1Response,
  A14S4Response,
  A14S5Response,
  ClickToSendTaskInstanceResponse,
  ClickToSendCaseDetailData,
  ClickToSendMailHistoryResponse,
  A14S19Response,
  PromotionInfo,
  GuideDocumentInfo
} from './IBModels'
import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'
import { UploadedFilesInfo } from '@pulseops/submission'
import { StringMap } from 'i18next'
const endpointCsApi = 'cs-api'
export enum RoleInsuranceBenefit {
  LA = 'LF',
  BEN = 'BN',
  SPEC_BEN = 'BE'
}
export namespace IBService {
  export const searchClientInfo = (data: ClientSearch): Task<ClientSearchResponse> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/client/search`)(ClientSearchResponse)(data),
      ZIO.map((res) => {
        return res
      })
    )

  export const searchRelatedPolicy = (data: PolicysSearch): Task<PolicySearchResponse> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/client/${data.clientNumber}/related-policies`)(PolicySearchResponse)(data),
      ZIO.map((res) => res)
    )

  export const getProductType = (policyNum: string): Task<ProductTypeC> =>
    pipe(
      POApi.get(`wf-api/policy/${policyNum}`)(ProductTypeC),
      ZIO.map((res) => res)
    )

  export const getInvestmentInfo = (data: extraInfoC): Task<ExtraInfoResponse> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policies/extra-info`)(ExtraInfoResponse)(data.data),
      ZIO.map((res) => res)
    )

  export const getBillingFrequency = (data: dataBillingFreq) =>
    pipe(
      POApi.post(`wf-api/policy/billing-change/freq-change-quotation`)(BillingFreqRes)(data),
      ZIO.map((res) => res)
    )

  export const getPolicyInfo = (policyNum: string): Task<PolicyInfoC> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}`)(PolicyInfoC),
      ZIO.map((res) => {
        return res
      })
    )

  export const getPremiumHistory = (data: PremiumHisReq) =>
    pipe(
      POApi.get(
        `${endpointCsApi}/inbound/policy/premium-history?policyNo=${data.data.policyNo}&clientNo=${data.data.clientNo}`
      )(PremiumHistoryInfo),
      ZIO.map((res) => res)
    )

  export const getPruCashBenInfo = (data: PruCashBenefitReq) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/loan-detail`)(PruCashBenefitDataInfo)(data.data),
      ZIO.map((res) => res.data)
    )

  export const getPolicyBonusReverse = (data: BonusReverseDataC): Task<BonusReverseInfoC> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/calculate-bonus-reverse`)(BonusReverseInfoC)(data.data),
      ZIO.map((res) => res)
    )

  export const getPremiumFrequencyDetail = (data: PolicyValueC): Task<PremiumFrequencyDetailResponseC> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/premium-frequency-detail`)(PremiumFrequencyDetailResponseC)(data),
      ZIO.map((res) => res)
    )

  export const getAPLValue = (policyNum: string, effectiveDate?: string): Task<APLValueResponseC> =>
    effectiveDate
      ? pipe(
          POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}/automatic-premium-loan`, {
            params: { effectiveDate: effectiveDate }
          })(APLValueResponseC),
          ZIO.map((res) => res)
        )
      : pipe(
          POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}/automatic-premium-loan`)(APLValueResponseC),
          ZIO.map((res) => res)
        )
  export const getOPLValue = (policyNum: string, effectiveDate?: string): Task<OPLValueResponseC> =>
    effectiveDate
      ? pipe(
          POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}/original-premium-loan`, {
            params: { effectiveDate: effectiveDate }
          })(OPLValueResponseC),
          ZIO.map((res) => res)
        )
      : pipe(
          POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}/original-premium-loan`)(OPLValueResponseC),
          ZIO.map((res) => res)
        )

  export const getBonusValue = (data: PolicyValueC): Task<BonusValueResponseC> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/bonus-value-detail`)(BonusValueResponseC)(data),
      ZIO.map((res) => res)
    )

  export const getPrucashBenefitDetail = (policyNum: string): Task<PrucashBenefitsResponseC> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNum}/prucash-benefit-detail`)(PrucashBenefitsResponseC),
      ZIO.map((res) => res)
    )

  export const getSurrenderValue = (data: PolicyValueC): Task<SurrenderValueResponseC> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/surrender-value-detail`)(SurrenderValueResponseC)(data),
      ZIO.map((res) => res)
    )

  export const getContractValue = (data: PolicyValueC): Task<ContractValueResponseC> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/contract-value`)(ContractValueResponseC)(data),
      ZIO.map((res) => res)
    )

  export const getCustomerProfile = (clientNumber: string): Task<CustomerProfileResponse> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/client/${clientNumber}/profile`)(CustomerProfileResponse),
      ZIO.map((res) => res)
    )

  export const getPolicySummary = (clientNumber: string): Task<PolicySummaryResponse> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/client/${clientNumber}/summary`)(PolicySummaryResponse),
      ZIO.map((res) => res)
    )

  export const getDuplicatePhone = (data: {
    phoneNumber: string
    clientNumber: string
  }): Task<DuplicatePhoneResponse> =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/client/duplicate-phone-number`)(DuplicatePhoneResponse)(data),
      ZIO.map((res) => res)
    )

  export const getCountPolicyInquiry = (clientNumber: string): Task<PolicyInquiryCountResponse> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/client/${clientNumber}/related-policies/count`)(PolicyInquiryCountResponse),
      ZIO.map((res) => res)
    )

  export const getPolicyList = (clientNumber: string) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/client/roles/policies`)(
        t.type({
          data: t.array(
            t.type({
              policyNumber: t.string
            })
          )
        })
      )({
        clientNumber: clientNumber
      }),
      ZIO.map((policyData) => {
        return policyData.data
      })
    )

  export const getAgentInfo = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/agents`)(AgentInfoResponse),
      ZIO.map((agentInfo) => {
        return agentInfo
      })
    )

  export const getFAODetail = (clientNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/client/${clientNumber}/business-representatives`)(FAOInfoResponse),
      ZIO.map((faoInfo) => {
        return faoInfo
      })
    )

  export const getInvestmentSummary = (policyNumber: string, productType: string, basicCode: string) =>
    pipe(
      POApi.get(
        `${endpointCsApi}/inbound/policy/${policyNumber}/investment-summary?productType=${productType}&productCode=${basicCode}`
      )(InvestmentSummaryResponse),
      ZIO.map((res) => res)
    )

  export const getInvestmentFund = () =>
    pipe(
      POApi.get(`${endpointCsApi}/master-data/t25-investment-fund`)(InvestmentFundResponse),
      ZIO.map((res) => res)
    )

  export const getProductPolicyByClientId = (policyNumber: string, clientId: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/products`, { params: { clientId: clientId } })(
        ProductResponse
      ),
      ZIO.map((products) => {
        return products
      })
    )

  export const getPartialWithdrawInfo = (policyNumber: string, productType: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/partial-with-draw?productCode=${productType}`)(
        PartialWithdrawResponse
      ),
      ZIO.map((res) => res)
    )

  export const postPartialWithdrawInfo = (data: PartialWithdrawInfoRequest) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/partial-with-draw`)(PartialWithdrawResponse)(data),
      ZIO.map((res) => res)
    )

  export const getDetailFundValue = (policyNumber: string, fundCode: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/fund/detail-fund-value?policyNumber=${policyNumber}&fundCode=${fundCode}`)(
        DetailFundValueResponse
      ),
      ZIO.map((res) => res)
    )

  export const ACMVQuery = (
    policyNumber: string,
    functions: string,
    transCode?: string,
    accountingCodes?: { sacsCode: string; sacsType: string }[],
    includeReceipt?: boolean
  ) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/accounting/transaction-history`)(ACMVQueryResponse)({
        function: functions,
        payload: {
          policyNo: policyNumber,
          transCode: transCode,
          accountingCodes: accountingCodes,
          includeReceipt: includeReceipt
        }
      }),
      ZIO.map((res) => res)
    )

  export const getReinData = (policyNum: string, reinstatementDate: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/reinstatement/policy/${policyNum}?effectiveDate=${reinstatementDate}`)(
        ReinResponse
      ),
      ZIO.map((c) => c)
    )

  export const UnitLinkFundPrice = (data: { fundCode: string; fundName: string; fromDate: string; toDate: string }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/fund/unit-link-fund-price`)(UnitLinkFundPriceResponse)(data),
      ZIO.map((res) => res)
    )

  export const getListMainProduct = () =>
    pipe(
      POApi.get(`${endpointCsApi}/outbound/table/t24-product`)(MainProductResponse),
      ZIO.map((res) => res)
    )

  export const getListClientInsuranBenefit = (policyNumber: string, role: RoleInsuranceBenefit) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/clients?role=${role}`)(
        t.type({
          data: t.array(ClientInsuranceInfo)
        })
      ),
      ZIO.map((clientInfo) => {
        return clientInfo
      })
    )

  export const getLinkDocByComponent = (componentCode: string) =>
    pipe(
      POApi.get(`cs-api/outbound/policy/component-document?componentCode=${componentCode}`)(
        t.type({ data: Maybe(t.array(RelatedDocumentInfo)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getTopUpHistory = (policyNumber: string, productType: string, transCode: string) =>
    pipe(
      POApi.get(
        `${endpointCsApi}/inbound/policy/${policyNumber}/top-up-history?productType=${productType}&transCode=${transCode}`
      )(TopUpHistoryResponse),
      ZIO.map((res) => res)
    )

  export const getTopUpHistoryDetail = (policyNumber: string, transNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/top-up/transaction-detail?policyNumber=${policyNumber}&transNo=${transNo}`)(
        TopUpHistoryDetailResponse
      ),
      ZIO.map((res) => res)
    )

  export const getFundSwitchingDetail = (policyNumber: string, transNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/fund/switching-detail?policyNumber=${policyNumber}&transNo=${transNo}`)(
        FundSwitchingDetailResponse
      ),
      ZIO.map((res) => res)
    )

  export const getPremiumRedirectionHistory = (data: {
    pageIndex: number
    itemsPerPage: number
    policyNo: string
    codes: string[]
    validFlag: boolean
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/las-transaction-history`)(PremiumRedirectionHistoryResponse)(data),
      ZIO.map((res) => res)
    )

  export const getFundValueUnit = (data: { fundCode: string; fundName: string; fromDate: string; toDate: string }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/fund/unit-link-fund-price`)(FundValueUnitResponse)(data),
      ZIO.map((res) => res)
    )

  export const getProductPolicy = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/products`)(ProductResponse),
      ZIO.map((products) => {
        return products
      })
    )

  export const getClaimPaymentDetail = (policyNumber: string, sacsCode: string, sacsType: string) =>
    pipe(
      POApi.post(`cs-api/inbound/accounting/transaction-history`)(t.type({ data: t.array(ClaimPaymentDetailInfo) }))({
        payload: { policyNo: policyNumber, accountingCodes: [{ sacsCode, sacsType }] },
        function: 'GET_ACCOUNTING_HISTORY_BY_CODE'
      }),
      ZIO.map((res) => res.data)
    )

  export const getClaimPaymentList = (policyNumber: string, accountGroups: { sacsCode: string; sacsType: string }[]) =>
    pipe(
      POApi.post(`cs-api/inbound/policy/account-balance`)(
        t.type({
          data: t.type({
            accountGroups: Maybe(
              t.array(
                t.type({
                  amount: t.number,
                  sacsCode: t.string,
                  sacsType: t.string
                })
              )
            )
          })
        })
      )({ policyNum: policyNumber, accountGroups: accountGroups, function: 'TOTAL' }),
      ZIO.map((res) => res.data)
    )

  export const getPartialWithdrawHistoryDetail = (data: { policyNo: string; transNo: string; transCode: string }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/unit-transaction-detail`)(PartialWithdrawHistoryResponse)(data),
      ZIO.map((res) => res)
    )

  export const getAccountValueInfo = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/funds`)(AccountValueInfoResponse),
      ZIO.map((res) => res)
    )

  export const getAccountValueDetail = (data: {
    pageIndex: number
    itemsPerPage: number
    orders: string[]
    sort: string
    fundCode: string
    policyNumber: string
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/fund-account-transaction`)(AccountValueDetailResponse)(data),
      ZIO.map((res) => res)
    )
  export const getLoanRepayInquiry = (policyNumber: string, effectiveDate: string) =>
    pipe(
      POApi.post(`wf-api/contract/contract-service-policy-loan-action-b`)(LoanRepayInquiryResponse)({
        body: {
          contractNumber: policyNumber,
          effectiveDate: effectiveDate
        }
      }),
      ZIO.map((res) => res)
    )

  export const getPaidUpInquiry = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/paid-up`)(PaidUpInquiryResponse),
      ZIO.map((res) => res)
    )

  export const searchDraftTransaction = (data: DraftTransactionSearch) =>
    pipe(
      POApi.post(`${endpointCsApi}/transaction/save-draft/query`)(DraftTransactionResponse)(data),
      ZIO.map((res) => {
        return res
      })
    )

  export const saveDraft = (data: SaveDraft, uploadedFilesInfo: UploadedFilesInfo) =>
    pipe(
      !!uploadedFilesInfo
        ? GeneralService.getAzureStorageFiles(
            uploadedFilesInfo?.uploadFiles,
            uploadedFilesInfo.transactionType,
            uploadedFilesInfo.docTypeCode,
            uploadedFilesInfo.category,
            uploadedFilesInfo.policyNumber,
            uploadedFilesInfo.officeCode
          )
        : ZIO.succeed([]),
      ZIO.flatMap((azuredFiles) => {
        data.payload.documentUrls = azuredFiles.map((i) => ({ url: i.url, type: i.name }))
        return POApi.post(`${endpointCsApi}/transaction/save-draft`)(SaveDraftResponse)(data)
      }),
      ZIO.map((res) => res)
    )

  export const searchDraftTransactionById = (id: string) =>
    pipe(
      POApi.post(`${endpointCsApi}/transaction/save-draft/query`)(DraftTransactionResponse)({
        pageIndex: 0,
        itemsPerPage: 10,
        id: id,
        status: 'VALID'
      }),
      ZIO.map((res) => {
        return res.records.length > 0 ? res.records[0] : undefined
      })
    )

  export const deleteDraftTransaction = (id: string) =>
    pipe(POApi.delError()(`${endpointCsApi}/transaction/save-draft/${id}`)(t.unknown))

  export const getPrurewardAgent = (clientNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/pru-reward/${clientNo}/agent`)(PrurewardAgentResponse),
      ZIO.map((res) => res)
    )

  export const postRewardPointHistory = (data: {
    pageIndex: number
    itemsPerPage: number
    orders: string[]
    sort: string
    pointType: string
    status: string
    clientNo: string
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/pru-reward/reward-point-history`)(PrurewardPointHistoryResponse)(data),
      ZIO.map((res) => res)
    )

  export const getTierHistory = (clientNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/pru-reward/${clientNo}/tier-history`)(PrurewardTierHistoryResponse),
      ZIO.map((res) => res)
    )

  export const postRedeemCodeHistory = (data: {
    pageIndex: number
    itemsPerPage: number
    orders: string[]
    sort: string
    codeUsingStatus: string
    clientNo: string
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/pru-reward/redeem-code-history`)(PrurewardRedeemCodeHistoryResponse)(data),
      ZIO.map((res) => res)
    )
  export const postGiftRedemptionHistory = (data: {
    pageIndex: number
    itemsPerPage: number
    orders: string[]
    sort: string
    deliveryStatus: string
    clientNo: string
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/pru-reward/gift-redemption-history`)(PrurewardGiftRedemptionHistoryResponse)(
        data
      ),
      ZIO.map((res) => res)
    )

  export const getPrurewardCustomerNote = (clientNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/pru-reward/${clientNo}/customer-note`)(PrurewardCustomerNoteResponse),
      ZIO.map((res) => res)
    )

  export const getPrurewardCustomerInfo = (clientNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/pru-reward/${clientNo}/customer-info`)(PrurewardCustomerInfoResponse),
      ZIO.map((res) => res)
    )

  export const getChangePOHistory = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/owner-history`)(ChangePOHistoryResponse),
      ZIO.map((res) => res)
    )

  export const getPolicyNoteWarning = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/warnings`)(PolicyNoteWarningResponse),
      ZIO.map((res) => res)
    )

  export const CheckNoteInformation = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/check-note-information`)(CheckNoteInformationResponse),
      ZIO.map((res) => res)
    )

  export const getPolicyNoteDetail = (policyNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNumber}/notes`)(PolicyNoteDetailResponse),
      ZIO.map((res) => res)
    )

  export const getOfficeCodesByBankCode = (bankCode: string) =>
    pipe(
      POApi.get(`wf-api/table/t33/get-office`)(
        t.type({
          body: t.array(OfficeCode)
        })
      ),
      ZIO.map((res) => {
        return res.body.find((element) => element.bankCode === bankCode)
      })
    )

  export const getNBUWInfo = (proposalNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/nbuw/${proposalNumber}`)(NBUWInfoResponse),
      ZIO.map((NBUWdata) => {
        return NBUWdata
      })
    )

  export const getNoteNBUWInfo = (clientNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/nbuw/uw-note?clientId=${clientNumber}`)(
        t.type({ data: t.array(NoteNBUWResponse) })
      ),
      ZIO.map((data) => {
        return data
      })
    )

  export const getListPaymentInfo = (policyNo: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNo}/payments`)(PaymentListResponse),
      ZIO.map((res) => res)
    )

  export const getPaymentInfoDetail = (policyNo: string, paymentNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/policy/${policyNo}/payment-info/${paymentNumber}`)(PaymentInfoDetailResponse),
      ZIO.map((res) => res)
    )

  export const getPrurewardsSummary = (clientNumber: string): Task<PrurewardsSummaryResponse> =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/pru-reward/${clientNumber}/summary`)(PrurewardsSummaryResponse),
      ZIO.map((res) => res)
    )

  export const getT5538 = () =>
    pipe(
      POApi.get(`wf-api/table/t5538/get-all`)(PartialWithdrawal.T5538),
      ZIO.map(({ body }) => body)
    )

  export const getLasTransactionHistory = (data: {
    policyNo: string
    pageIndex: number
    itemsPerPage: number
    orders: string[]
    sort: string
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/las-transaction-history`)(LasTransactionHistoryResponse)(data),
      ZIO.map((res) => res)
    )

  export const getListMasterDataProduct = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/master-data/products`)(MasterProductsResponse),
      ZIO.map((res) => res)
    )

  export const getPremiumPaymentHistoryPruOnline = (
    data: {
      proposalNo: string
      refNo: string
      pageIndex: number
      itemsPerPage: number
    },
    policyNo: string
  ) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/policy/${policyNo}/premium-payment-history`)(PremiumPaymentHistoryResponse)(
        data
      ),
      ZIO.map((res) => res)
    )

  export const searchClaim = (dataSearch: SearchClaimInquiry) =>
    pipe(
      POApi.post(`${endpointCsApi}/inbound/claim/search`)(SearchClaimResponse)(dataSearch),
      ZIO.map((res) => res)
    )

  export const getListClaimNumber = (clientNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/claim-numbers?clientNumber=${clientNumber}`)(
        t.type({ data: Maybe(t.array(t.string)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getClaimCaseDetail = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}`)(t.type({ data: Maybe(ClaimCaseDetail) })),
      ZIO.map((res) => res.data)
    )

  export const getMedicalInfo = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/medical-info`)(
        t.type({ data: Maybe(t.array(MedicalInfoData)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getPendingNoticeList = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/pending-notice`)(
        t.type({ data: Maybe(t.array(PendingNoticeData)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getPolicyAndRider = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/policies`)(
        t.type({ data: Maybe(t.array(PolicyRiderData)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getBenefitItem = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/benefit-items`)(
        t.type({ data: Maybe(BenefitItemData) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getReceiptsInfo = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/receipts`)(
        t.type({ data: Maybe(t.array(ReceiptsDataList)) })
      ),
      ZIO.map((res) => res.data)
    )

  export const getDocsClaim = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/documents`)(t.type({ data: Maybe(DocumentsClaimData) })),
      ZIO.map((res) => res.data)
    )

  export const getPayMode = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/pay-mode`)(t.type({ data: Maybe(PayModeData) })),
      ZIO.map((res) => res.data)
    )

  export const getPaymentAllocation = (claimNumber: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/${claimNumber}/payment-allocation`)(
        t.type({ data: Maybe(PaymentAllocationData) })
      ),
      ZIO.map((res) => res.data)
    )
  export const getListMainProductPolicyInquiry = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/master-data/products`)(MainProductsResponse),
      ZIO.map((res) => res)
    )

  export const getA15Character = (code?: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/outbound/table/a15-character${code ? `?code=${code}` : ''}`)(A15CharacterResponse),
      ZIO.map((res) => res)
    )

  export const getLastestCustomerBehavior = (clientId: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/outbound/customer/${clientId}/behavior`)(LastestCustomerBehaviorResponse),
      ZIO.map((res) => res)
    )

  export const updateCustomerBehavior = (data: CustomerBehaviorData) =>
    pipe(
      POApi.post(`${endpointCsApi}/outbound/customer/behavior`)(LastestCustomerBehaviorResponse)(data),
      ZIO.map((res) => res)
    )

  export const getLastestHistoryCustomerBehavior = (data: {
    clientNumber: string
    pageIndex: number
    itemsPerPage: number
  }) =>
    pipe(
      POApi.post(`${endpointCsApi}/outbound/customer/behavior-history`)(LastestHistoryCustomerBehaviorResponse)(data),
      ZIO.map((res) => res)
    )

  export const getExtensionNumberList = (userEmail: string) =>
    // userEmail: string
    pipe(
      POApi.get(
        `${endpointCsApi}/inbound/config-data/t4s18config?email=${userEmail}`
        // ?email=${userEmail}
      )(
        t.type({
          data: t.array(ExtensionNumberReponse)
        })
      ),
      ZIO.map((responseData) => {
        const extensionNumber =
          responseData && responseData.data && responseData.data.length > 0 ? responseData.data[0].extensionCode : ''
        return extensionNumber
      })
    )

  export const makeCallAvaya = (extensionNumber: string, phoneNumber: string) => {
    const generateUUID = () => {
      let uuidValue = '',
        k,
        randomValue
      for (k = 0; k < 32; k++) {
        randomValue = (Math.random() * 16) | 0

        if (k === 8 || k === 12 || k === 16 || k === 20) {
          uuidValue += '-'
        }
        uuidValue += (k === 12 ? 4 : k === 16 ? (randomValue & 3) | 8 : randomValue).toString(16)
      }
      return uuidValue
    }

    return pipe(
      POApi.post(
        `${endpointCsApi}/avaya-gateway/make-call?extensionNumber=${extensionNumber}&phoneNumber=${phoneNumber}&source=PulseForOps-Inbound`,
        { headers: { traceId: generateUUID() } }
      )(MakeCallResponse)(undefined),
      ZIO.foldM(
        (error) => ZIO.fail(error),
        (responseData) => {
          return ZIO.succeed(responseData.data?.callId)
        }
      )
    )
  }

  export const searchCallbackAppointment = (data: CallbackAppointmentSearch) =>
    pipe(
      POApi.post(`pulseops/api/v1/inbound/callback-appointment/search`)(CallbackAppointmentResponse)(data),
      ZIO.map((res) => {
        return res
      })
    )
  export const getDocsList = (policyNum: string, folderName: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/filenet/documents?policyNumber=${policyNum}&filenetClazzName=${folderName}`)(
        DocsResponse
      ),
      ZIO.map((res) => res)
    )

  export const downloadURL = (url: string) =>
    pipe(
      ZIO.zipPar(AuthService.token, AuthService.getLoginType),
      ZIO.flatMap(([token, loginType]) =>
        ZIO.fromPromise(() => {
          return fetch(url, {
            method: 'GET',
            headers: {
              'Cache-control': 'no-cache',
              Pragma: 'no-cache',
              'Content-type': 'application/json; charset=utf-8',
              Authorization: `Bearer ${token}`,
              'X-Authen-Vendor': loginType
            }
          })
        })
      )
    )
  export const getDocsClaimAdvanceList = (policyNum: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/policy/${policyNum}/verify-letters`)(DocsClaimAdvanceResponse),
      ZIO.map((res) => res)
    )

  export const getClaimLetter = (id: number) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/claim/letter/${id}`)(ClaimLetterResponse),
      ZIO.map((res) => res)
    )
  export const getA14S0 = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/a14s0-mail-group`)(A14S0Response),
      ZIO.map((res) => res.data)
    )

  export const getA14S1 = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/a14s1-mail-type`)(A14S1Response),
      ZIO.map((res) => res.data)
    )

  export const getA14S4 = (code: string) =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/a14s4-mail-template?code=${code}`)(A14S4Response),
      ZIO.map((res) => res.data)
    )

  export const getA14S5 = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/a14s5-bcc-mail`)(A14S5Response),
      ZIO.map((res) => res.data)
    )

  export const submitClickToSend = (data: {
    emailTo: string[]
    emailBCC: string[]
    subject: string
    content: string
    policyNumber: string
    clientNumber: string
    policyOwnerName: string
    cceGroupCode: string
    requestTypeCode: string
    categoryCode: string
    attachDocuments: { url: string; type: string; filename: string }[]
    mailGroupCode: string
    note: string
  }) => pipe(POApi.post(`${endpointCsApi}/inbound/click-to-send-email/submit`)(t.unknown)(data))

  export const getListClickToSendTaskInstance = (data: {
    start: number
    size: number
    sort: string
    order: string
    taskCategory: string
    policyNumber: string
    clientNumber: string
    fromDate: string
    toDate: string
    emailTo: string
    categoryCode: string
    cceGroupCode?: string
    createdBy?: string
  }) =>
    pipe(
      POApi.post(`pulseops/api/v1/inbound/click-to-send-email/task-instance`)(ClickToSendTaskInstanceResponse)(data),
      ZIO.map((res) => res)
    )

  const newId = pipe(
    ZIO.effectTotal(() => randomBytes(11).toString('base64').slice(0, 11)),
    ZIO.map((str) => str.replace(/\+/g, '-').replace(/\//g, '-'))
  )

  export const importFileClickToSend = (file: File, policyNum: string) => {
    const oldExtension = file.name ? file.name.slice(file.name.lastIndexOf('.')) : ''
    const fileName = file.name ? file.name.slice(0, file.name.lastIndexOf('.')) : ''
    const filterNewName = replaceSpecialCharactersInFileName(fileName)
    const fullFilterName = filterNewName + oldExtension
    const newFile = new File([file], fullFilterName, { type: file.type })

    return pipe(
      newId,
      ZIO.flatMap((id) =>
        pipe(
          ZIO.zipPar(
            POApi.put(`azurestorage`, {
              headers: {
                'Content-Type': `${file.type}`,
                Container: 'contactstrategy',
                Blob: `CLICKTOSEND/${policyNum}/${id}_${fullFilterName}`
              }
            })(t.unknown)(newFile),
            ZIO.succeed(id)
          )
        )
      ),
      ZIO.map(([doc, id]) => {
        return { type: `CLICKTOSEND/${policyNum}/${id}_${fullFilterName}`, filename: file.name }
      })
    )
  }

  export const getTeamCodeByUser = (userEmail: string) =>
    // userEmail: string
    pipe(
      POApi.get(
        `${endpointCsApi}/inbound/config-data/t4s18config?email=${userEmail}`
        // ?email=${userEmail}
      )(
        t.type({
          data: t.array(ExtensionNumberReponse)
        })
      ),
      ZIO.map((responseData) => {
        const teamCode =
          responseData && responseData.data && responseData.data.length > 0 ? responseData.data[0].teamCode : ''
        return teamCode
      })
    )

  export const getClickToSendCaseDetail = (processInstanceId: string) =>
    pipe(
      POApi.get(`pulseops/api/v1/inbound/click-to-send-email/historic-process-instance/${processInstanceId}`)(
        ClickToSendCaseDetailData
      ),
      ZIO.map((res) => res)
    )

  export const getListUserCreatedByEmail = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/t4s18config?email=`)(
        t.type({
          data: t.array(ExtensionNumberReponse)
        })
      ),
      ZIO.map((responseData) => {
        return responseData
      })
    )

  export const postCompleteTaskClickToSend = (data: {
    processInstanceId: string
    taskId: string
    decision: string
    taskCategory: string
    categoryCode: string
    comment: string
    payload: {
      emailTo?: string[] | null
      emailBCC?: string[] | null
      subject?: string | null
      content?: string | null
      policyNumber?: string | null
      clientNumber?: string | null
      policyOwnerName?: string | null
      cceGroupCode?: string | null
      requestTypeCode?: string | null
      categoryCode?: string | null
      attachDocuments?: { url?: string; type?: string; filename?: string }[] | null
      mailGroupCode?: string | null
      note?: string | null
    }
  }) =>
    pipe(
      POApi.post(`pulseops/api/v1/inbound/click-to-send-email/complete-task`)(t.unknown)(data),
      ZIO.map((res) => res)
    )

  export const getClickToSendMailHistory = (data: {
    start: number
    size: number
    sort: string
    order: string
    policyNumber: string
    clientNumber: string
    fromDate: string
    toDate: string
    emailTo: string
    status: string
    categoryCode: string
    cceGroupCode: string
    createdBy: string
    lastUpdatedBy: string
  }) =>
    pipe(
      POApi.post(`pulseops/api/v1/inbound/click-to-send-email/historic-process-instance`)(
        ClickToSendMailHistoryResponse
      )(data),
      ZIO.map((res) => {
        return res
      })
    )

  export const getA14S19 = () =>
    pipe(
      POApi.get(`${endpointCsApi}/inbound/config-data/t4s19-config`)(
        t.type({
          data: t.array(A14S19Response)
        })
      ),
      ZIO.map((responseData) => {
        return responseData
      })
    )

  export const getPromotionInfo = (policyNumber: string) =>
    pipe(
      POApi.get(`communication-rest/promotion?policyNumber=${policyNumber}`)(
        t.type({ data: Maybe(PromotionInfo) })
      ),
      ZIO.map((promotionInfos) => promotionInfos),
      ZIO.catchAll((e) => {
        return ZIO.fail(e)
      })
    )

  export const getGuidelineDocumentList = (transactionType: string) =>
    pipe(
      POApi.get(
        `cs-api/outbound/table/t10-communication?processDecision=Send&transactionType=${transactionType}&categoryCode=IB`
      )(
        t.type({
          data: t.array(GuideDocumentInfo),
          code: Maybe(t.string),
          message: Maybe(t.string)
        })
      ),
      ZIO.map((responseInfo) => (responseInfo.data && responseInfo.data.length > 0 ? responseInfo.data : []))
    )

  export const postSendZNS = (data: {
    policyNumber: string
    pushCode: string
    requestTime: string
    feature: string
    phoneNumber: string
  }) =>
    pipe(
      POApi.post(`communication-rest/reminder/send`)(t.type({ message: t.string }))(data),
      ZIO.map((res) => res)
    )
}
